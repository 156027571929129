import React, { useEffect,  useContext } from "react";

import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import Breadcrumbs from "../common/breadcrumbs";
import { Calendar } from "primereact/calendar";
// import { TimePicker } from "antd";
// import type { Dayjs } from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import BASE_URL from '../../config';
import axios from 'axios';
import { useParams ,useLocation } from 'react-router-dom';
import { ReservationContext } from './ReservationContext';
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookingCheckout = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const { carId } = useParams();
  const context = useContext(ReservationContext);
  const location = useLocation();
  const { startDate, returnDate } = location.state || {};
  console.log(startDate,returnDate);
  
  if (!context) {
    throw new Error('ReservationContext must be used within a ReservationProvider');
  }

  const { reservationData, setReservationData } = context;
  // Fetch car details (if not already fetched)
  useEffect(() => {
    if (!reservationData.car) {
      const fetchCarDetails = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/api/client/cars/${carId}`);
          setReservationData((prevData) => ({
            ...prevData,
            car: response.data,
          }));
        } catch (error) {
          console.error('Failed to fetch car details:', error);
        }
      };

      if (carId) {
        fetchCarDetails();
      }
    }
  }, [carId, reservationData.car, setReservationData]);
  // Calculate total price
  useEffect(() => {
    const calculateTotalPrice = () => {
      const { startDate, returnDate, car } = reservationData;
      
  
      if (startDate && returnDate && car) {
        const start = new Date(startDate);
        const end = new Date(returnDate);

        if (end >= start) {
          const timeDiff = end.getTime() - start.getTime();
          const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

          const total = dayDiff * car.pricePerDay;
          setReservationData((prevData) => ({
            ...prevData,
            totalPrice: total,
          }));
        } else {
          setReservationData((prevData) => ({
            ...prevData,
            totalPrice: 0,
          }));
        }
      } else {
        setReservationData((prevData) => ({
          ...prevData,
          totalPrice: 0,
        }));
      }
    };

    calculateTotalPrice();
  }, [reservationData.startDate, reservationData.returnDate, reservationData.car, setReservationData]);

  if (!reservationData.car) {
    return <div>Loading...</div>;
  }

  const handleButtonClick = () => {
    const { startDate, returnDate } = reservationData;

    // Check for missing dates
    if (!startDate) {
      toast.error("Please select a Start Date", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return; // Exit if start date is missing
    }

    if (!returnDate) {
      toast.error("Please select a Return Date", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return; // Exit if return date is missing
    }

    // Proceed to navigate path if validation passes
    navigatePath();
  };

  // Handler functions
  const handleRentalTypeChange = (value: string) => {
    setReservationData((prevData) => ({
      ...prevData,
      rentalType: value,
    }));
  };

  const handleSameReturnLocationChange = (value: boolean) => {
    setReservationData((prevData) => ({
      ...prevData,
      sameReturnLocation: value,
    }));
  };
  const today = new Date();

  const handleDeliveryLocationChange = (value: string) => {
    setReservationData((prevData) => ({
      ...prevData,
      deliveryLocation: value,
    }));
  };

  const handleReturnLocationChange = (value: string) => {
    setReservationData((prevData) => ({
      ...prevData,
      returnLocation: value,
    }));
  };

  const handleStartDateChange = (value: Date) => {
    setReservationData((prevData) => ({
      ...prevData,
      startDate: value,
    }));
  };

  const handleReturnDateChange = (value: Date) => {
    setReservationData((prevData) => ({
      ...prevData,
      returnDate: value,
    }));
  };

  const navigatePath = () => {
    navigate(routes.bookingDetail);
  };
console.log(" reservation data stored " , reservationData);

  return (
    <ReservationContext.Provider value={{ reservationData, setReservationData }}>
    <div>
    <ToastContainer />
      <Breadcrumbs title="Checkout" subtitle="Checkout" />
      <div className="booking-new-module">
        <div className="container">
          <div className="booking-wizard-head">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-3">
                <div className="booking-head-title">
                  <h4>Reserve Your Car</h4>
                  <p>Complete the following steps</p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="booking-wizard-lists">
                  <ul>
                    <li className="active">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-01.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Location &amp; Time</h6>
                    </li>
                    {/* <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-02.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Add-Ons</h6>
                    </li> */}
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-03.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Detail</h6>
                    </li>
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-04.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Checkout</h6>
                    </li>
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-05.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Booking Confirmed</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-detail-info">
            <div className="row">
              <div className="col-lg-4 theiaStickySidebar">
                <div className="stickybar">
                  <div className="booking-sidebar">
                    <div className="booking-sidebar-card">
                      <div className="booking-sidebar-head">
                        <h5>Car Details</h5>
                      </div>
                      <div className="booking-sidebar-body">
                        <div className="booking-car-detail">
                          <span className="car-img">
                            {reservationData.car.images && reservationData.car.images.length > 0 ? (
                              <Link to={`${routes.listingDetails}/${reservationData.car._id}`}>
                                <img
                                  src={`${BASE_URL}/api/uploads/${reservationData.car.images[0]}`} // Fetching the first image dynamically
                                  className="img-fluid"
                                  alt={reservationData.car.name}
                                />
                              </Link>
                            ) : (
                              <div>No Image Available</div>
                            )}
                          </span>
                          <div className="care-more-info">
                            <h5>{reservationData.car.name}</h5>
                            <p>{reservationData.car.location}</p> {/* Update this line if you have location data */}
                            <Link to={`${routes.listingDetails}/${reservationData.car._id}`}>View Car Details</Link>
                          </div>
                        </div>

                        <div className="booking-vehicle-rates">
                          <ul>
                            <li>
                              <div className="rental-charge">
                                <h6>
                                  Rental Charges Rate <span> (1 day )</span>
                                </h6>
                                <span className="text-danger">
                                  (This does not include fuel)
                                </span>
                              </div>
                              <h5>{reservationData.car.pricePerDay} MAD </h5>
                            </li>
                            
                            {/* <li>
                              <h6>Refundable Deposit</h6>
                              <h5>+$1200</h5>
                            </li> */}
                            <li className="total-rate">
                              <h6>Subtotal</h6>
                              <h5>{reservationData.car.pricePerDay} MAD </h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="booking-sidebar-card">
                      <div className="booking-sidebar-head d-flex justify-content-between align-items-center">
                        <h5>Coupon</h5>
                        <Link to="#" className="coupon-view">
                          View Coupons
                        </Link>
                      </div>
                      <div className="booking-sidebar-body">
                        <form>
                          <div className="d-flex align-items-center">
                            <div className="form-custom flex-fill">
                              <input
                                type="text"
                                className="form-control mb-0"
                                placeholder="Coupon code"
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-secondary apply-coupon-btn d-flex align-items-center ms-2"
                            >
                              Apply
                              <i className="feather icon-arrow-right ms-2" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* total price display */}
                    <div className="total-rate-card">
                      <div className="vehicle-total-price">
                        <h5>Estimated Total</h5>
                        <span>
                          {reservationData.totalPrice > 0
                            ? `${reservationData.totalPrice.toFixed(2)} MAD`
                            : 'Please select valid dates'}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="booking-information-main">
                  {/* rent form  */}
                  <form>
      {/* Rental Type Selection */}
      <div className="booking-information-card">
        <div className="booking-info-head">
          <span>
            <i className="bx bxs-car-garage" />
          </span>
          <h5>Rental Type</h5>
        </div>
        <div className="booking-info-body">
          <ul className="booking-radio-btns">
            <li>
              <label className="booking_custom_check">
                <input
                  type="radio"
                  name="rent_type"
                  id="location_delivery"
                  value="delivery"
                  checked={reservationData.rentalType === 'delivery'}
                  onChange={(e) => handleRentalTypeChange(e.target.value)}
                />
                <span className="booking_checkmark">
                  <span className="checked-title">Delivery</span>
                </span>
              </label>
            </li>
            <li>
              <label className="booking_custom_check">
                <input
                  type="radio"
                  name="rent_type"
                  id="location_pickup"
                  value="pickup"
                  checked={reservationData.rentalType === 'pickup'}
                  onChange={(e) => handleRentalTypeChange(e.target.value)}
                />
                <span className="booking_checkmark">
                  <span className="checked-title">Self Pickup</span>
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      {/* Delivery Location Section */}
      {reservationData.rentalType === 'delivery' && (
        <div className="booking-information-card delivery-location">
          <div className="booking-info-head">
            <span>
              <i className="bx bxs-car-garage" />
            </span>
            <h5>Location</h5>
          </div>
          <div className="booking-info-body">
            {/* Delivery Location Input */}
            <div className="form-custom">
              <label className="form-label">Delivery Location</label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control mb-0"
                  placeholder="Add Delivery Location"
                  value={reservationData.deliveryLocation}
                  onChange={(e) => handleDeliveryLocationChange(e.target.value)}
                />
                <Link
                  to="#"
                  className="btn btn-secondary location-btn d-flex align-items-center"
                >
                  <i className="bx bx-current-location me-2" />
                  Current Location
                </Link>
              </div>
            </div>

            {/* Return to Same Location Checkbox */}
            <div className="input-block m-0">
              <label className="custom_check d-inline-flex location-check">
                <span>Return to same location</span>
                <input
                  type="checkbox"
                  name="return_same_location"
                  checked={reservationData.sameReturnLocation}
                  onChange={(e) => handleSameReturnLocationChange(e.target.checked)}
                />
                <span className="checkmark" />
              </label>
            </div>

            {/* Return Location Input */}
            {!reservationData.sameReturnLocation && (
              <div className="form-custom">
                <label className="form-label">Return Location</label>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control mb-0"
                    placeholder="Add Return Location"
                    value={reservationData.returnLocation}
                    onChange={(e) => handleReturnLocationChange(e.target.value)}
                  />
                  <Link
                    to="#"
                    className="btn btn-secondary location-btn d-flex align-items-center"
                  >
                    <i className="bx bx-current-location me-2" />
                    Current Location
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Pickup Location Section */}
      {reservationData.rentalType === 'pickup' && (
        <div className="booking-information-card pickup-location">
          <div className="booking-info-head">
            <span>
              <i className="bx bxs-car-garage" />
            </span>
            <h5>Location</h5>
          </div>
          <div className="booking-info-body">
            {/* Pickup Location (Car's Location) */}
            <div className="form-custom">
              <label className="form-label">Pickup Location</label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control mb-0"
                  value={reservationData.car.location}
                  readOnly
                />
              </div>
            </div>

            {/* Return to Same Location Checkbox */}
            <div className="input-block m-0">
              <label className="custom_check d-inline-flex location-check">
                <span>Return to same location</span>
                <input
                  type="checkbox"
                  name="return_same_location"
                  checked={reservationData.sameReturnLocation}
                  onChange={(e) => handleSameReturnLocationChange(e.target.checked)}
                />
                <span className="checkmark" />
              </label>
            </div>

            {/* Return Location Input */}
            {!reservationData.sameReturnLocation && (
              <div className="form-custom">
                <label className="form-label">Return Location</label>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control mb-0"
                    placeholder="Add Return Location"
                    value={reservationData.returnLocation}
                    onChange={(e) => handleReturnLocationChange(e.target.value)}
                  />
                  <Link
                    to="#"
                    className="btn btn-secondary location-btn d-flex align-items-center"
                  >
                    <i className="bx bx-current-location me-2" />
                    Current Location
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Booking Date & Time Section */}
      <div className="booking-information-card booking-type-card">
        <div className="booking-info-head">
          <span>
            <i className="bx bxs-location-plus" />
          </span>
          <h5>Booking Date &amp; Time</h5>
        </div>
        <div className="booking-info-body">
          <div className="booking-timings">
            <div className="row">
              {/* Start Date */}
              <div className="col-md-6">
                <div className="input-block date-widget">
                  <label className="form-label">Start Date</label>
                  <div className="group-img">
                    <Calendar
                      className="datetimepicker bg-custom"
                      value={reservationData.startDate}
                      onChange={(e) => handleStartDateChange(e.value)}
                      placeholder="Choose Date"
                      minDate={today}
                    />
                    <span className="input-cal-icon">
                      <i className="bx bx-calendar" />
                    </span>
                  </div>
                </div>
              </div>

              {/* Start Time */}
              {/* <div className="col-md-6">
                <div className="input-block time-widge">
                  <label className="form-label">Start Time</label>
                  <div className="group-img style-custom">
                    <TimePicker
                      placeholder="Choose Time"
                      className="form-control timepicker"
                    
                    />
                    <span className="input-cal-icon">
                      <i className="bx bx-time" />
                    </span>
                  </div>
                </div>
              </div> */}

              {/* Return Date */}
              <div className="col-md-6">
                <div className="input-block date-widget">
                  <label className="form-label">Return Date</label>
                  <div className="group-img">
                    <Calendar
                      className="datetimepicker bg-custom"
                      value={reservationData.returnDate}
                      onChange={(e) => handleReturnDateChange(e.value)}
                      placeholder="Choose Date"
                      minDate={today}
                    />
                    <span className="input-cal-icon">
                      <i className="bx bx-calendar" />
                    </span>
                  </div>
                </div>
              </div>

              {/* Return Time */}
              {/* <div className="col-md-6">
                <div className="input-block time-widge">
                  <label className="form-label">Return Time</label>
                  <div className="group-img style-custom">
                    <TimePicker
                      placeholder="Choose Time"
                      className="form-control timepicker"
                 
                    />
                    <span className="input-cal-icon">
                      <i className="bx bx-time" />
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Estimated Total Price */}
      {/* <div className="total-rate-card">
        <div className="vehicle-total-price">
          <h5>Estimated Total</h5>
          <span>
            {reservationData.totalPrice > 0
              ? `$${reservationData.totalPrice.toFixed(2)}`
              : 'Please select valid dates'}
          </span>
        </div>
      </div> */}

      {/* Navigation Buttons */}
      <div className="booking-info-btns d-flex justify-content-end">
        <Link to={`${routes.listingDetails}/${reservationData.car._id}`} className="btn btn-secondary">
          Back to Car details
        </Link>
        <button
          onClick={handleButtonClick}
          className="btn btn-primary continue-book-btn"
          type="button"
        >
          Continue Booking
        </button>
      </div>
    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ReservationContext.Provider>
  );
};

export default BookingCheckout;
