import React, { useEffect , useState} from "react";
import Breadcrumbs from "../common/breadcrumbs";
import AOS from "aos";
import "aos/dist/aos.css";
// import { useSelector } from "react-redux";
// import { ContactUs } from "../../core/data/interface/interface";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
// import { Link } from "react-router-dom";
import Header from "../common/header";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import BASE_URL from '../../config';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { useLanguage } from "../../core/context/LanguageContext";

const Contact = () => {
  // const data = useSelector((state: ContactUs) => state.contactdata);
  const { translations } = useLanguage(); 

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState(""); // Adding setSubject to manage subject state
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(`${BASE_URL}/api/send-email`, {
        name,
        email,
        message,
        subject
      });
     // setMessage("Message sent successfully!");
     setName('');
     setEmail('');
     setMessage('');
     setSubject('');
     toast.success('Message sent successfully!');


    } catch (error) {
      console.error("There was an error sending the email!", error);
      toast.error('Failed to send message.')
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="main-wrapper">
      <Header />
      <Breadcrumbs title={translations.contact.title} subtitle="Pages" />
      <ToastContainer />
      <Helmet>
        {/* <title>Contact CarDND - Get Support and Rental Assistance Now</title> */}
        <title>{translations.contact.pageTitle}</title>

        <meta name="description" content={translations.contact.description} />
        <link rel="canonical" href="https://cardnd.com/contact-us" />
      </Helmet>
      <section className="contact-section">
        <div className="container">
          <div className="contact-info-area">
            <div className="row">
            {translations.contact.contactData.map((info, index) => (
                <div key={index} className="col-lg-3 col-md-6 col-12">
                  <div className="single-contact-info">
                    <span><i className={info.icon} /></span>
                    <h3>{info.title}</h3>
                    {info.type === "phone" ? (
                      <a href={info.link}>{info.text}</a>
                    ) : (
                      <p><a href={info.link}>{info.text}</a></p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="form-info-area"
            data-aos="fade-down"
            data-aos-duration={1200}
            data-aos-delay="0.5"
          >
            <div className="row">
              <div className="col-lg-6 d-flex">
                <ImageWithBasePath
                  src="assets/img/contact-info.jpg"
                  className="img-fluid"
                  alt="Contact"
                />
              </div>
              <div className="col-lg-6">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <h1>{translations.contact.formTitle}</h1>
                  <div className="col-md-12">
                    <div className="input-block">
                      <label>{translations.contact.name} <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block">
                      <label>{translations.contact.email} <span className="text-danger">*</span></label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block">
                      <label>{translations.contact.subject} <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block">
                      <label>{translations.contact.comments} <span className="text-danger">*</span></label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn contact-btn" disabled={loading}>
                  {loading ? translations.contact.sending : translations.contact.sendButton}
                </button>
              </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
