import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../common/breadcrumbs";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { toast, ToastContainer } from 'react-toastify';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import { all_routes } from "../router/all_routes";
// import { Dropdown } from "primereact/dropdown";
import "yet-another-react-lightbox/styles.css";
import { useParams } from 'react-router-dom';
import BASE_URL from '../../config';
import axiosInstance from '../../core/services/axiosInstance';
import { useAuth } from "../../core/context/AuthContext"; 
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

const listingDetails = () => {
  const routes = all_routes;
  const { id } = useParams(); // This will get the id from the URL
  const [car, setCar] = useState(null);
  const navigate = useNavigate(); 

  const [date1, setDate1] = useState(null); // Pickup date
  const [date2, setDate2] = useState(null); // Return date

// Modal state
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  // const token = localStorage.getItem('token'); // Adjust based on your auth implementation
  const { isAuthenticated } = useAuth();

  const handleRentNow = async (carId) => {
    console.log('handleRentNow called with carId:', carId);
  
    if (!date1 || !date2) {
      console.log('Dates not selected');
      setModalMessage('Please select both pickup and return dates.');
      setShowModal(true);
      return;
    }
  
    if (date1 > date2) {
      console.log('Invalid date range');
      setModalMessage('Return date must be after pickup date.');
      setShowModal(true);
      return;
    }
  
    try {
      console.log('Checking availability...');
      const response = await axios.get(`${BASE_URL}/api/client/check-availability/${carId}`, {
        params: {
          startDate: date1.toISOString(),
          returnDate: date2.toISOString(),
        },
      });
  
      console.log('Availability response:', response.data);
  
      if (response.data.available) {
        console.log('Car is available. Navigating to checkout...');
        navigate(`/pages/booking-checkout/${carId}`);
      } else {
        console.log('Car is not available.');
        setModalMessage('The car is not available for the selected dates. Please choose different dates.');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error checking car availability:', error);
      setModalMessage('An error occurred while checking availability. Please try again later.');
      setShowModal(true);
    }
  };
  

// ratting logic 
const [ratings, setRatings] = useState({
  service: 0,
  carCondition: 0,
  valueForMoney: 0,
  carPerformance: 0,
  cleanliness: 0,
});

const [comment, setComment] = useState('');
const [ratingsData, setRatingsData] = useState([]);
const [averageRatings, setAverageRatings] = useState(null);


const handleRatingChange = (factor, value) => {
  const numericValue = Number(value);
  console.log(`Rating change for ${factor}:`, numericValue);
  setRatings((prevRatings) => ({
    ...prevRatings,
    [factor]: numericValue,
  }));
};

useEffect(() => {
  fetchRatings();
  fetchAverageRatings();
}, [id]);

const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate that all ratings are provided
  const allRatingsProvided = Object.values(ratings).every((value) => value > 0);
  console.log("rating data : " , ratings);  
  if (!allRatingsProvided) {
    alert('Please provide ratings for all factors.');
    return;
  }

  try {
    // No need to retrieve the token manually or include headers
    const response = await axiosInstance.post(
      `/api/cars/${id}/ratings`,
      {
        ...ratings,
        comment,
      }
    );

    if (response.status === 201) {
      toast.success('Rating submitted successfully!');
      // Optionally reset the form or fetch updated ratings
      setRatings({
        service: 0,
        carCondition: 0,
        valueForMoney: 0,
        carPerformance: 0,
        cleanliness: 0,
      });
      setComment('');
      fetchRatings();
      fetchAverageRatings();

    } else {
      // Handle unexpected status codes
      alert(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    // Enhanced error logging
    if (error.response) {
      console.error('Failed to submit rating:', error.response.data);
      alert(`Failed to submit rating: ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      console.error('No response received:', error.request);
      alert('Failed to submit rating: No response from server.');
    } else {
      console.error('Error setting up request:', error.message);
      alert(`Failed to submit rating: ${error.message}`);
    }
  }
};

// Define fetchRatings function
const fetchRatings = async () => {
  try {
    const response = await axiosInstance.get(`/api/cars/${id}/ratings`);
    setRatingsData(response.data);
  } catch (error) {
    console.error('Failed to fetch ratings:', error);
  }
};

// Define fetchAverageRatings function
const fetchAverageRatings = async () => {
  try {
    const response = await axiosInstance.get(`/api/cars/${id}/average-ratings`);
    setAverageRatings(response.data);
  } catch (error) {
    console.error('Error fetching average ratings:', error);
  }
};

// useEffect to fetch ratings when component mounts or id changes
useEffect(() => {
  fetchRatings();
  fetchAverageRatings();
}, [id]);

// reviews pagination 
const [currentPage, setCurrentPage] = useState(1);
const reviewsPerPage = 5;

const totalReviews = ratingsData.length;
const totalPages = Math.ceil(totalReviews / reviewsPerPage);

const indexOfLastReview = currentPage * reviewsPerPage;
const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
const currentReviews = ratingsData.slice(indexOfFirstReview, indexOfLastReview);


  const bigImgSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  // const [selectedLocation, setSelectedLocation] = useState(null);
  // const [selectedLocation1, setSelectedLocation1] = useState(null);


 


  // const types = [
  //   { name: " Newyork Office - 78, 10th street Laplace USA" },
  //   { name: "Newyork Office - 12, 5th street USA" },
  // ];
  // const Location = [
  //   { name: " Newyork Office - 78, 10th street Laplace USA" },
  //   { name: "Newyork Office - 12, 5th street USA" },
  // ];

 

  useEffect(() => {
    if (bigImgSliderRef.current && thumbnailSliderRef.current) {
      bigImgSliderRef.current.slickGoTo(0);
      thumbnailSliderRef.current.slickGoTo(0);
    }
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  
  // const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  
  useEffect(() => {
    // setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);
  
  const settings1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,             // Enable autoplay
    autoplaySpeed: 3000,        // Time between slides in milliseconds (e.g., 3000ms = 3 seconds)
    pauseOnHover: true,         // Optional: Pause autoplay on hover
  };
  



useEffect(() => {
  const fetchCarDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/client/cars/${id}`);
      setCar(response.data);
    } catch (error) {
      console.error('Failed to fetch car details:', error);
    }
  };

  fetchCarDetails();
}, [id]);

useEffect(() => {
  console.log("car data ", car);
}, [car]);

// **Add this conditional check**
if (!car) {
  return <div>Loading...</div>;
}




  return (
    <div className="main-wrapper">
      <ToastContainer />

      <Breadcrumbs title= {car?.name} subtitle="Listings" />
      <>
        {/* Detail Page Head*/}
        <section className="product-detail-head">
          <div className="container">
            <div className="detail-page-head">
              <div className="detail-headings">
                <div className="star-rated">
                  <ul className="list-rating">
                    <li>
                      <div className="car-brand">
                        <span>
                          <ImageWithBasePath
                            src="assets/img/icons/car-icon.svg"
                            alt="img"
                          />
                        </span>
                        {car?.brand}
                      </div>
                    </li>
                    <li>
                      <span className="year">2024</span>
                    </li>
                    <li className="ratings">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span className="d-inline-block average-list-rating">
                        (5.0)
                      </span>
                    </li>
                  </ul>
                  <div className="camaro-info">
                    <h3>{car?.name}</h3>
                    <div className="camaro-location">
                      <div className="camaro-location-inner">
                        <i className="bx bx-map" />
                        <span>{car?.location}</span>
                      </div>
                      <div className="camaro-location-inner">
                        <i className="bx bx-show" />
                        <span>Views : 250 </span>
                      </div>
                      <div className="camaro-location-inner">
                        <i className="bx bx-car" />
                        <span>Views : Listed on: 01 Jan, 2024 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="details-btn">
                <span className="total-badge">
                  <i className="bx bx-calendar-edit" />
                  Total Booking : 300
                </span>
                {/* <Link to="#">
                  {" "}
                  <i className="bx bx-git-compare" />
                  Compare
                </Link> */}
              </div>
            </div>
          </div>
        </section>
        {/* /Detail Page Head*/}
      </>

      <section className="section product-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="detail-product">
                <div className="pro-info">
                  <div className="pro-badge">
                    <span className="badge-km">
                      <i className="fa-solid fa-person-walking" />
                      4.2 Km Away
                    </span>
                    <Link to="#" className="fav-icon">
                      <i className="fa-regular fa-heart" />
                    </Link>
                  </div>
                  <ul>
                    <li className="del-airport">
                      <i className="fa-solid fa-check" />
                      Airport delivery
                    </li>
                    <li className="del-home">
                      <i className="fa-solid fa-check" />
                      Home delivery
                    </li>
                  </ul>
                </div>

                <div className="slider detail-bigimg">
                <Slider
                  {...settings1}
                  ref={slider1}
                  asNavFor={nav2}
                >
                  {car.images?.length > 0 ? (
                    car.images.map((image, index) => (
                      <div key={index} className="product-img">
                        <img
                          src={`${BASE_URL}/api/uploads/${image}`}
                          alt={`Car Image ${index + 1}`}
                          className="img-fluid"
                        />
                      </div>
                    ))
                  ) : (
                    <div>No Images Available</div>
                  )}
                </Slider>
              </div>
              </div>
              <>
                <div className="review-sec pb-0">
                  <div className="review-header">
                    <h4>Extra Service</h4>
                  </div>
                  <div className="lisiting-service">
                    <div className="row">
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-01.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>GPS Navigation Systems</p>
                        </div>
                      </div>
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-02.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>Wi-Fi Hotspot</p>
                        </div>
                      </div>
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-03.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>Child Safety Seats</p>
                        </div>
                      </div>
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-04.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>Fuel Options</p>
                        </div>
                      </div>
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-05.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>Roadside Assistance</p>
                        </div>
                      </div>
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-06.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>Satellite Radio</p>
                        </div>
                      </div>
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-07.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>Additional Accessories</p>
                        </div>
                      </div>
                      <div className="servicelist d-flex align-items-center col-xxl-3 col-xl-4 col-sm-6">
                        <div className="service-img">
                          <ImageWithBasePath
                            src="assets/img/icons/service-08.svg"
                            alt="Icon"
                          />
                        </div>
                        <div className="service-info">
                          <p>Express Check-in/out</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Listing Section */}
                <div className="review-sec mb-0">
                  <div className="review-header">
                    <h4>Description of Listing</h4>
                  </div>
                  <div className="description-list">
                    <p>
                    {car?.description}
                    </p>
                    
                    
                  </div>
                </div>
                {/* /Listing Section */}
                {/* Specifications */}
                <div className="review-sec specification-card ">
                  <div className="review-header">
                    <h4>Specifications</h4>
                  </div>
                  <div className="card-body">
                    <div className="lisiting-featues">
                      <div className="row">
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-1.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>Body </span>
                            <h6> Sedan</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-2.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>Make </span>
                            <h6> Nisssan</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-3.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>Transmission </span>
                            <h6> Automatic</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-4.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>Fuel Type </span>
                            <h6> Diesel</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-5.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>Mileage </span>
                            <h6>16 Km</h6>
                          </div>
                        </div>
                        
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-7.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>Year</span>
                            <h6> 2018</h6>
                          </div>
                        </div>
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-8.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>AC </span>
                            <h6> Air Condition</h6>
                          </div>
                        </div>
                       
                        <div className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6">
                          <div className="feature-img">
                            <ImageWithBasePath
                              src="assets/img/specification/specification-icon-10.svg"
                              alt="Icon"
                            />
                          </div>
                          <div className="featues-info">
                            <span>Door </span>
                            <h6> 4 Doors</h6>
                          </div>
                        </div>
                        
                      
                      </div>
                    </div>
                  </div>
                </div>
                {/* Specifications */}
                {/* Car Features */}
                <div className="review-sec listing-feature">
                  <div className="review-header">
                    <h4>Car Features</h4>
                  </div>
                  <div className="listing-description">
                    <div className="row">
                      <div className="col-md-4">
                        <ul>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Multi-zone A/C
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Heated front seats
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Andriod Auto
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Navigation system
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <ul>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Premium sound system
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Bluetooth
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Keyles Start
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Memory seat
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <ul>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            6 Cylinders
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Adaptive Cruise Control
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            Intermittent wipers
                          </li>
                          <li>
                            <span>
                              <i className="bx bx-check-double" />
                            </span>
                            4 power windows
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Car Features */}
             
                {/* Video */}
                {/* <div className="review-sec mb-0">
                  <div className="review-header">
                    <h4>Video</h4>
                  </div>
                  <div className="short-video">
                    <iframe
                      src="https://www.youtube.com/embed/ExJZAegsOis"
                      width={100}
                      height={350}
                    />
                  </div>
                </div> */}
                {/* /Video */}
                {/* FAQ */}
                <div className="review-sec faq-feature">
                  <div className="review-header">
                    <h4>FAQ’s</h4>
                  </div>
                  <div className="faq-info">
                    <div className="faq-card">
                      <h4 className="faq-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#faqOne"
                          aria-expanded="false"
                        >
                       What are the speed limits in Morocco?
                        </Link>
                      </h4>
                      <div id="faqOne" className="card-collapse collapse">
                        <p>
                        While driving urban areas, you are required to abide by the speed limit of 60 km/h whereas the speed limit outside the urban areas is 100 km/h. On highways, the speed limit is generally around 120 km/h
                        </p>
                      </div>
                    </div>
                    <div className="faq-card">
                      <h4 className="faq-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#faqTwo"
                          aria-expanded="false"
                        >
                          Do I need an International Driving Permit (IDP) to rent a car in Morocco?

                        </Link>
                      </h4>
                      <div id="faqTwo" className="card-collapse collapse">
                        <p>
                        To rent a car in Morocco, you will need an International Driving Permit only if your driver’s license is in a non-roman alphabet.
                        </p>
                      </div>
                    </div>
                    <div className="faq-card">
                      <h4 className="faq-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#faqThree"
                          aria-expanded="false"
                        >
                          What types of vehicles are available for rent?
                        </Link>
                      </h4>
                      <div id="faqThree" className="card-collapse collapse">
                        <p>
                          We offer a diverse fleet of vehicles to suit every
                          need, including compact cars, sedans, SUVs and luxury
                          vehicles. You can browse our selection online or
                          contact us for assistance in choosing the right
                          vehicle for you
                        </p>
                      </div>
                    </div>
                    <div className="faq-card">
                      <h4 className="faq-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#faqFour"
                          aria-expanded="false"
                        >
                        Can I rent this car with a new driving license in Casablanca?
                        </Link>
                      </h4>
                      <div id="faqFour" className="card-collapse collapse">
                        <p>
                        If you have a new driving license that &apos s less than six months old, please check with the car rental agent as the requirement varies based on the insurance policy of the respective car. Some are available for new driving license holders at a higher security deposit amount, with limited insurance coverage and liable for higher excess fee while others are only available with older licenses.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /FAQ */}
                {/* Policies */}
                <div className="review-sec">
                  <div className="review-header">
                    <h4>Policies</h4>
                  </div>
                  <div className="policy-list">
                    <div className="policy-item">
                      <div className="policy-info">
                        <h6>Cancellation Charges</h6>
                        <p>
                          Cancellation charges will be applied as per the policy
                        </p>
                      </div>
                      <Link to={routes. privacyPolicy}>Know More</Link>
                    </div>
                    <div className="policy-item">
                      <div className="policy-info">
                        <h6>Policy</h6>
                        <p>
                          I hereby agree to the terms and conditions of the
                          Lease Agreement with Host
                        </p>
                      </div>
                      <Link to={routes.privacyPolicy}>View Details</Link>
                    </div>
                  </div>
                </div>
                {/* /Policies */}

                {/* Reviews */}
                {averageRatings && (
                  <div className="review-sec listing-review">
                    <div className="review-header">
                      <h4>Reviews</h4>
                    </div>
                    <div className="rating-wrapper">
                      <div className="rating-wraps">
                        <h2>
                          {averageRatings.averageRating.toFixed(1)}
                          <span>/5</span>
                        </h2>
                        <h6>Based on {averageRatings.totalReviews} Reviews</h6>
                      </div>
                      <div className="rating-progress">
                        <div className="progress-info">
                          <h6>Service</h6>
                          <div className="progress" role="progressbar">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: `${(averageRatings.service / 5) * 100}%` }}
                            />
                          </div>
                          <div className="progress-percent">{averageRatings.service.toFixed(1)}</div>
                        </div>
                        <div className="progress-info">
                          <h6>Car Condition</h6>
                          <div className="progress" role="progressbar">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: `${(averageRatings.carCondition / 5) * 100}%` }}
                            />
                          </div>
                          <div className="progress-percent">{averageRatings.carCondition.toFixed(1)}</div>
                        </div>
                        <div className="progress-info">
                          <h6>Value for Money</h6>
                          <div className="progress" role="progressbar">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: `${(averageRatings.valueForMoney / 5) * 100}%` }}
                            />
                          </div>
                          <div className="progress-percent">{averageRatings.valueForMoney.toFixed(1)}</div>
                        </div>
                        <div className="progress-info">
                          <h6>Car Performance</h6>
                          <div className="progress" role="progressbar">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: `${(averageRatings.carPerformance / 5) * 100}%` }}
                            />
                          </div>
                          <div className="progress-percent">{averageRatings.carPerformance.toFixed(1)}</div>
                        </div>
                        <div className="progress-info">
                          <h6>Cleanliness</h6>
                          <div className="progress" role="progressbar">
                            <div
                              className="progress-bar bg-primary"
                              style={{ width: `${(averageRatings.cleanliness / 5) * 100}%` }}
                            />
                          </div>
                          <div className="progress-percent">{averageRatings.cleanliness.toFixed(1)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

<div className="review-sec listing-review">
      <div className="review-card">
        <div className="review-head">
          <h6>Showing guest reviews</h6>
        </div>
        {totalReviews === 0 ? (
          <p>No reviews yet. Be the first to review this car!</p>
        ) : (
          <>
            <ul>
              {currentReviews.map((rating) => (
                <li key={rating._id}>
                  <div className="review-wraps wrap-card">
                    <div className="review-header-group">
                      <div className="review-widget-header">
                        <div className="review-design">
                          <h6>{rating.clientId.clientname}</h6>
                          <p>
                            Reviewed{' '}
                            {new Date(rating.createdAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </p>
                        </div>
                      </div>
                      <div className="reviewbox-list-rating">
                        <p>
                          {[...Array(5)].map((_, index) => {
                            const difference = rating.averageRating - index;
                            let starClass = 'far fa-star';

                            if (difference >= 1) {
                              starClass = 'fas fa-star filled';
                            } else if (difference >= 0.5) {
                              starClass = 'fas fa-star-half-alt filled';
                            }

                            return <i key={index} className={starClass} />;
                          })}
                          <span> ({rating.averageRating.toFixed(1)})</span>
                        </p>
                      </div>
                    </div>
                    <p>{rating.comment}</p>
                  </div>
                </li>
              ))}
            </ul>

            {/* Pagination Controls */}
            {totalPages > 1 && (
              <div className="pagination-wrapper">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className="btn btn-primary"
                      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    >
                      Prev
                    </button>&nbsp;
                  </li>
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <li
                        key={pageNumber}
                        className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                      >
                        <button
                          className="btn btn-primaryy" 
                          onClick={() => setCurrentPage(pageNumber)}
                        >
                          {pageNumber}
                        </button>&nbsp;
                      </li>
                    );
                  })}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                      className="btn btn-primary"
                      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}
      </div>
      </div>
                {/* /Reviews */}
              

                {isAuthenticated ? (

                <div className="review-sec leave-reply-form mb-0">
                  {/* Leave a Reply */} 
                  
                  <div className="review-header">
                    <h4>Leave a Reply</h4>
                  </div>
                  <div className="review-list-rating">
                    <div className="row">
                      <div className="col-xl-4 col-md-6">
                        {/* Example for Service */}
                          <div className="set-rating">
                            <p>Service</p>
                            <div className="rating-selection">
                              {[5,4,3,2,1].map((value) => (
                                <React.Fragment key={`service-${value}`}>
                                <input
                                  type="radio"
                                  id={`service${value}`}
                                  name="service"
                                  value={value}
                                  checked={ratings.service === value}
                                  onChange={() => handleRatingChange('service', value)}
                                />
                                <label htmlFor={`service${value}`} />
                              </React.Fragment>
                              
                              ))}
                            </div>
                          </div>

                      </div>
                      <div className="col-xl-4 col-md-6">
                        <div className="set-rating">
                          <p>Car Condition</p>
                          <div className="rating-selection">
                            {[5,4,3,2,1].map((value) => (
                              <React.Fragment key={`carCondition-${value}`}>
                                <input
                                  type="radio"
                                  id={`carCondition${value}`}
                                  name="carCondition"
                                  value={value}
                                  checked={ratings.carCondition === value}
                                  onChange={() => handleRatingChange('carCondition', value)}
                                />
                                <label htmlFor={`carCondition${value}`} />
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-md-6">
                        <div className="set-rating">
                          <p>Value for Money</p>
                          <div className="rating-selection">
                            {[5,4,3,2,1].map((value) => (
                              <React.Fragment key={`valueForMoney-${value}`}>
                                <input
                                  type="radio"
                                  id={`valueForMoney${value}`}
                                  name="valueForMoney"
                                  value={value}
                                  checked={ratings.valueForMoney === value}
                                  onChange={() => handleRatingChange('valueForMoney', value)}
                                />
                                <label htmlFor={`valueForMoney${value}`} />
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-md-6">
                        <div className="set-rating">
                          <p>Car Performance</p>
                          <div className="rating-selection">
                            {[5,4,3,2,1].map((value) => (
                              <React.Fragment key={`carPerformance-${value}`}>
                                <input
                                  type="radio"
                                  id={`carPerformance${value}`}
                                  name="carPerformance"
                                  value={value}
                                  checked={ratings.carPerformance === value}
                                  onChange={() => handleRatingChange('carPerformance', value)}
                                />
                                <label htmlFor={`carPerformance${value}`} />
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-md-6">
                        <div className="set-rating">
                          <p>Cleanliness</p>
                          <div className="rating-selection">
                            {[5,4,3,2,1].map((value) => (
                              <React.Fragment key={`cleanliness-${value}`}>
                                <input
                                  type="radio"
                                  id={`cleanliness${value}`}
                                  name="cleanliness"
                                  value={value}
                                  checked={ratings.cleanliness === value}
                                  onChange={() => handleRatingChange('cleanliness', value)}
                                />
                                <label htmlFor={`cleanliness${value}`} />
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="card-body">
                    <div className="review-list">
                      <ul>
                        <li className="review-box feedbackbox mb-0">
                          <div className="review-details">
                            <form className="#" 
                            onSubmit={handleSubmit} >
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="input-block">
                                    <label>Comments </label>
                                    <textarea
                                            rows={4}
                                            className="form-control"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                          />

                                  </div>
                                </div>
                              </div>
                              <div className="submit-btn text-end">
                              <button
                                  className="btn btn-primary submit-review"
                                  type="submit"
                                >
                                  {" "}
                                  Submit Review
                                </button>
                              </div>
                            </form>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
                  ) : (
                    <div className="review-sec leave-reply-form mb-0 blur-section">
                      <div className="review-header">
                        <h4>Please log in to leave a review</h4>
                      </div>
                    </div>
                    )}
                {/* /Leave a Reply */}
              </>
            </div>
            <div className="col-lg-4 theiaStickySidebar">
              <div className="stickybar">
                <div className="review-sec mt-0">
                  <div className="review-header">
                    <h4>Pricing Details</h4>
                  </div>
                  <div className="price-list">
                    <div className="price-item">
                      <p>Price Per Day </p>
                      <h6>{car?.pricePerDay} MAD </h6>
                    </div>
                    
                  </div>
                </div>
                <div className="review-sec mt-0">
                  <div className="review-header">
                    <h4>Check Availability</h4>
                  </div>
                  <div className="location-content">
                    
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="delivery">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <ul>
                            
                          
                            <li className="column-group-main">
                              <div className="input-block m-0">
                                <label>Pickup Date</label>
                              </div>
                              <div className="input-block-wrapp sidebar-form">
                                <div className="input-block  me-lg-2">
                                  <div className="group-img">
                                    <div className="form-wrap">
                                      <Calendar
                                        value={date1}
                                        onChange={(e) => setDate1(e.value)}
                                        placeholder="04/11/2023"
                                      />
                                    
                                      <span className="form-icon">
                                        <i className="fa-regular fa-calendar-days" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </li>
                            <li className="column-group-main">
                              <div className="input-block m-0">
                                {" "}
                                <label>Return Date</label>
                              </div>
                              <div className="input-block-wrapp sidebar-form">
                                <div className="input-block date-widget">
                                  <div className="group-img">
                                    <div className="form-wrap">
                                      <Calendar
                                        value={date2}
                                        onChange={(e) => setDate2(e.value)}
                                        placeholder="04/11/2023"
                                      />
                                      
                                      <span className="form-icon">
                                        <i className="fa-regular fa-calendar-days" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </li>
                            <li className="column-group-last">
                              <div className="input-block mb-0">
                                <div className="search-btn">
                                  {/* <Link
                                    to={routes.bookingCheckout}
                                    className="btn btn-primary check-available w-100"
                                  >
                                    Book
                                  </Link> */}
                                  <button
                                    className="btn btn-primary check-available w-100"

                                    
                                    onClick={() => handleRentNow(car._id)}
                                  >
                                    <i className="feather icon-calendar me-2" ></i>
                                    Rent Now
                                  </button>
                                  <Link
                                    to="/contact-us"
                                    data-bs-toggle="modal"
                                    data-bs-target="#enquiry"
                                    className="btn btn-theme"
                                  >
                                    Enquire Us
                                  </Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </form>
                      </div>
                      <div className="tab-pane fade" id="pickup">
                      <div onSubmit={(e) => e.preventDefault()}>
                          <ul>
                            <li className="column-group-main">
                              <div className="input-block m-0">
                                <label>Pickup Date</label>
                              </div>
                              <div className="input-block-wrapp sidebar-form">
                                <div className="input-block me-lg-2">
                                  <div className="group-img">
                                    <div className="form-wrap">
                                      <Calendar
                                        value={date1}
                                        onChange={(e) => setDate1(e.value)}
                                        placeholder="Select Pickup Date"
                                        minDate={new Date()}
                                      />
                                      <span className="form-icon">
                                        <i className="fa-regular fa-calendar-days" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="column-group-main">
                              <div className="input-block m-0">
                                <label>Return Date</label>
                              </div>
                              <div className="input-block-wrapp sidebar-form">
                                <div className="input-block date-widget">
                                  <div className="group-img">
                                    <div className="form-wrap">
                                      <Calendar
                                        value={date2}
                                        onChange={(e) => setDate2(e.value)}
                                        placeholder="Select Return Date"
                                        minDate={date1 || new Date()}
                                      />
                                      <span className="form-icon">
                                        <i className="fa-regular fa-calendar-days" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="column-group-last">
                              <div className="input-block mb-0">
                                <div className="search-btn">
                                  <button
                                    className="btn btn-primary check-available w-100"
                                    type="button"
                                    onClick={() => handleRentNow(car._id)}
                                  >
                                    <i className="feather icon-calendar me-2"></i>
                                    Rent Now
                                  </button>
                                  <Link
                                    to="/contact-us"
                                    data-bs-toggle="modal"
                                    data-bs-target="#enquiry"
                                    className="btn btn-theme"
                                  >
                                    Enquire Us
                                  </Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
               
                <div className="review-sec share-car mt-0">
                  <div className="review-header">
                    <h4>View Car Location</h4>
                  </div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
                    className="iframe-video"
                  />
                </div>
                <div className="review-sec share-car mt-0 mb-0">
                  <div className="review-header">
                    <h4>Share</h4>
                  </div>
                  <ul className="nav-social">
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook-f fa-facebook fi-icon" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-instagram fi-icon" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-behance fi-icon" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-pinterest-p fi-icon" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter fi-icon" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-linkedin fi-icon" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>

      <div
        className="modal custom-modal fade check-availability-modal"
        id="pages_edit"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="form-header text-start mb-0">
                <h4 className="mb-0 text-dark fw-bold">Availability Details</h4>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="align-center" aria-hidden="true">
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="available-for-ride">
                    <p>
                      <i className="fa-regular fa-circle-check" />
                      Chevrolet Camaro is available for a ride
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="row booking-info">
                    <div className="col-md-4 pickup-address">
                      <h5>Pickup</h5>
                      <p>45, 4th Avanue Mark Street USA</p>
                      <span>Date &amp; time : 11 Jan 2023</span>
                    </div>
                    <div className="col-md-4 drop-address">
                      <h5>Drop Off</h5>
                      <p>78, 10th street Laplace USA</p>
                      <span>Date &amp; time : 11 Jan 2023</span>
                    </div>
                    <div className="col-md-4 booking-amount">
                      <h5>Booking Amount</h5>
                      <h6>
                        <span>$300 </span> /day
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="booking-info seat-select">
                    <h6>Extra Service</h6>
                    <label className="custom_check">
                      <input
                        type="checkbox"
                        name="rememberme"
                        className="rememberme"
                      />
                      <span className="checkmark" />
                      Baby Seat - <span className="ms-2">$10</span>
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="booking-info pay-amount">
                    <h6>Deposit Option</h6>
                    <div className="radio radio-btn">
                      <label>
                        <input type="radio" name="radio" /> Pay Deposit
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="radio" /> Full Amount
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" />
                <div className="col-md-6">
                  <div className="booking-info service-tax">
                    <ul>
                      <li>
                        Booking Price <span>$300</span>
                      </li>
                      <li>
                        Extra Service <span>$10</span>
                      </li>
                      <li>
                        Tax <span>$5</span>
                      </li>
                    </ul>
                  </div>
                  <div className="grand-total">
                    <h5>Grand Total</h5>
                    <span>$315</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Link to={routes.booking} className="btn btn-back">
                Go to Details
                <i className="fa-solid fa-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={() => setShowModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

    </div>
    
  );
};

export default listingDetails;
