import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import en from '../../translations/en.json';  // Importer les traductions en anglais
import fr from '../../translations/fr.json';  // Importer les traductions en français

// Définir le type pour les valeurs de langue
interface LanguageContextType {
  selectedLanguage: string;
  setSelectedLanguage: (lang: string) => void;
  translations: { [key: string]: any };
}

// Créer le contexte avec des valeurs par défaut
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Fournisseur de contexte pour gérer la langue
export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en'); // Langue par défaut
  const [translations, setTranslations] = useState<any>(en); // Chargement initial de la langue par défaut

  useEffect(() => {
    // Charger les traductions en fonction de la langue sélectionnée
    if (selectedLanguage === 'fr') {
      setTranslations(fr);
    } else {
      setTranslations(en);
    }
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook personnalisé pour accéder au contexte
export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
