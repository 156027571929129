import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
import { set_is_mobile_sidebar } from "../../core/data/redux/action";
import { useAuth } from "../../core/context/AuthContext";
import axios from 'axios';
import BASE_URL from '../../config';
import { useLanguage } from "../../core/context/LanguageContext";


const Header = () => {
  const routes = all_routes;
  const location = useLocation();

  const { translations, selectedLanguage, setSelectedLanguage } = useLanguage();



  const dispatch = useDispatch();

  const mobileSidebar = useSelector((state: any) => state.mobileSidebar);

  const handleClick = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };

  const [mobileSubmenu, setMobileSubmenu] = useState(false);

  const mobileSubmenus = () => {
    setMobileSubmenu(!mobileSubmenu);
  };

  const [activeMenu, setActiveMenu] = useState("");

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    mobileSubmenus(); // Call the function if needed
  };


  // Destructure the authentication state and methods
  const { isAuthenticated, client, logout } = useAuth();

  // Handler for logout
  const handleLogout = () => {
    logout();
    // Optionally, navigate to a specific route after logout
  };


  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/categories`);
      console.log('Categories response:', response.data);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories!", error);
    }
  };
  return (
    <>
      <header className="header">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#" onClick={handleClick}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo">
                <ImageWithBasePath
                  src="assets/img/logon.svg"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo-small">
                <ImageWithBasePath
                  src="assets/img/logo-smalln.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routes.homeOne} className="menu-logo">
                  <ImageWithBasePath
                    src="assets/img/logo.svg"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={handleClick}
                >
                  {" "}
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li
                  // className={`has-submenu ${location.pathname.includes("") ? "active" : ""}`}
                  className={`has-submenu ${activeMenu === "Home" ? "active" : ""}`}
                >
                  <Link to={routes.homeOne} onClick={handleMenuClick}>
                    {translations.nav.home}

                  </Link>

                </li>
                {/* <li
                  className={`has-submenu ${location.pathname.includes("listing") ? "active" : ""}`}
                >
                  <Link to={routes.listingGrid}>
                    Listings  <i className="fas fa-chevron-down" />
                  </Link>
                  
                </li> */}
                <li
                  className={`has-submenu ${location.pathname.includes("listing") ? "active" : ""}`}
                >
                  <Link to="#">
                    {translations.nav.listings} <i className="fas fa-chevron-down" />
                  </Link>
                  <ul className="submenu">
                    <li
                      className={
                        location.pathname.includes(routes.listingGrid)
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={routes.listingGrid}>{translations.nav.allListings} </Link>
                    </li>
                    {categories.map((category) => (

                      <li key={category.id}
                        className={
                          location.pathname.includes(routes.listingList)
                            ? "active"
                            : ""
                        }
                      >

                        <Link to={`${routes.listingGrid}/${category.slug}`}>{category?.name}</Link>
                      </li>
                    ))}

                  </ul>
                </li>
                <li
                  className={`has-submenu ${location.pathname.includes("pages") ? "active" : ""}`}
                >
                  <Link to={routes.aboutUs}>
                    {translations.nav.aboutUs}

                  </Link>

                </li>
                <li
                  className={location.pathname.includes(routes.becamePartner) ? "active" : ""}
                >
                  <Link to={routes.becamePartner}>
                    {translations.nav.becamePartner}
                  </Link>
                </li>


                <li
                  className={`has-submenu ${location.pathname.includes("blog") ? "active" : ""}`}
                >
                  <Link to={routes.blogList}>
                    {translations.nav.blog}

                  </Link>

                </li>
                <li
                  className={
                    location.pathname.includes(routes.contactUs) ? "active" : ""
                  }
                >
                  <Link to={routes.contactUs}>{translations.nav.contact} </Link>
                </li>
                {!isAuthenticated && (
                  <>
                    <li className="login-link d-lg-none">
                      <Link to={routes.register}>{translations.nav.signUp} </Link>
                    </li>
                    <li className="login-link d-lg-none">
                      <Link to={routes.login}>{translations.nav.signIn}</Link>
                    </li>
                  </>
                )}
                {/* Mobile-Only Authenticated User Menu */}
                {isAuthenticated && (
                  <li className="has-submenu d-lg-none">
                    <a href="#" className="d-flex align-items-center">
                      <i className="fa-regular fa-user me-2" aria-hidden="true"></i>
                      <span>{client.clientname}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/user/user-wishlist">
                          <i className="fa-solid fa-heart me-2"></i>{translations.nav.wishlist}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/user/user-dashboard">
                          <i className="fa-solid fa-tachometer-alt me-2"></i>{translations.nav.dashboard}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/user/user-reviews">
                          <i className="fa-solid fa-star me-2"></i>{translations.nav.reviews}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/user/user-messages">
                          <i className="fa-solid fa-envelope me-2"></i>{translations.nav.messages}
                        </Link>
                      </li>
                      <li><hr className="dropdown-divider" /></li>
                      <li>
                        <button className="dropdown-item" onClick={handleLogout}>
                          <i className="fa-solid fa-sign-out-alt me-2"></i>{translations.nav.logout}
                        </button>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>

            <ul className="navbar-nav ms-auto d-none d-lg-flex">


              <li className="dropdown" style={{ position: 'relative', marginRight: '8px' }}>
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '600',
                    padding: '8px 12px',
                    borderRadius: '12px',
                    background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    color: '#333',
                    textDecoration: 'none',
                  }}
                >
                  <i
                    className="fas fa-globe"
                    style={{
                      fontSize: '20px',
                      color: 'black',
                    }}
                  ></i>

                </a>
                <ul className="dropdown-menu" style={{ minWidth: '200px' }}>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setSelectedLanguage('en')} // Change la langue en anglais
                      style={{ padding: '8px 12px', fontWeight: selectedLanguage === 'en' ? 'bold' : 'normal' }}
                    >
                      English
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setSelectedLanguage('fr')} // Change la langue en français
                      style={{ padding: '8px 12px', fontWeight: selectedLanguage === 'fr' ? 'bold' : 'normal' }}
                    >
                      Français
                    </a>
                  </li>
                </ul>
              </li>

              {isAuthenticated && client ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa-regular fa-user me-2" aria-hidden="true"></i>
                    <span>{client.clientname}</span>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                    <li>
                      <Link className="dropdown-item" to="/user/user-wishlist">
                        <i className="fa-solid fa-heart me-2"></i>{translations.nav.wishlist}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/user/user-dashboard">
                        <i className="fa-solid fa-tachometer-alt me-2"></i>{translations.nav.dashboard}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/user/user-reviews">
                        <i className="fa-solid fa-star me-2"></i>{translations.nav.reviews}
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/user/user-messages">
                        <i className="fa-solid fa-envelope me-2"></i>{translations.nav.messages}
                      </Link>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                      <button className="dropdown-item" onClick={handleLogout}>
                        <i className="fa-solid fa-sign-out-alt me-2"></i>{translations.nav.logout}
                      </button>
                    </li>
                  </ul>
                </li>
              ) : (
                <>
                  <li className="nav-item me-2">
                    <Link className="btn btn-primary" to={routes.login}>
                      <i className="fa-regular fa-user me-2" aria-hidden="true"></i>
                      Sign In
                    </Link>
                  </li>
                  <li className="nav-item me-2">
                    <Link className="btn btn-secondary" to={routes.register}>
                      <i className="fa-solid fa-lock me-2" aria-hidden="true"></i>
                      Sign Up
                    </Link>
                  </li>
                </>
              )}
            </ul>

          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
