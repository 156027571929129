import React ,{useContext} from 'react'
import ImageWithBasePath from '../../core/data/img/ImageWithBasePath'
import Breadcrumbs from '../common/breadcrumbs'
// import { Link } from "react-router-dom";
// import { all_routes } from "../router/all_routes";
import BASE_URL from '../../config';
import { differenceInDays } from 'date-fns'; 
import { ReservationContext } from './ReservationContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer } from 'react-toastify';

const BookingSuccess = () => {
  // const routes = all_routes;
  const { reservationData} = useContext(ReservationContext);

  if (!reservationData.car) {
    return <div>Loading...</div>;
  }
  console.log("the data coming : " , reservationData ) ; 

  const startDate = new Date(reservationData.startDate);
  const returnDate = new Date(reservationData.returnDate);
  const numberOfDays = differenceInDays(returnDate, startDate) || 1;

  const handlePrint = () => {
    console.log('handlePrint called');
    const input = document.getElementById('invoice');
    if (input) {
      html2canvas(input, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'a4');

        // Add logo
        const logo = new Image();
        logo.src = '/assets/img/logo.png'; // Adjust the path to your logo
        logo.onload = () => {
          pdf.addImage(logo, 'PNG', 50, 20, 100, 50); // Adjust the position and size
          pdf.setFontSize(20);
          pdf.text('Invoice', 50, 100); // Add a title

          // Add the captured content
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, 'PNG', 0, 150, pdfWidth, pdfHeight - 150);

          pdf.save('invoice.pdf');
        };

        logo.onerror = (error) => {
          console.error('Error loading logo image:', error);
          // Proceed without logo
          pdf.setFontSize(20);
          pdf.text('Invoice', 50, 50); // Adjust position since no logo

          // Add the captured content
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, 'PNG', 0, 100, pdfWidth, pdfHeight - 100);

          pdf.save('invoice.pdf');
        };
      }).catch((error) => {
        console.error('Error generating PDF:', error);
      });
    } else {
      console.error('Invoice element not found');
    }
  };
  
  return (
    <div >
      <ToastContainer/>

      <Breadcrumbs title="Checkout" subtitle="Checkout" />
         {/* Booking Success */}
      <div className="booking-new-module" >
        <div className="container" >
          <div className="booking-wizard-head">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-3">
                <div className="booking-head-title">
                  <h4>Reserve Your Car</h4>
                  <p>Complete the following steps</p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="booking-wizard-lists">
                  <ul>
                    <li className="active activated">
                      <span><ImageWithBasePath src="assets/img/icons/booking-head-icon-01.svg" alt="Booking Icon" /></span>
                      <h6>Location &amp; Time</h6>
                    </li>
                    {/* <li className="active activated">
                      <span><ImageWithBasePath src="assets/img/icons/booking-head-icon-02.svg" alt="Booking Icon" /></span>
                      <h6>Add-Ons</h6>
                    </li> */}
                    <li className="active activated">
                      <span><ImageWithBasePath src="assets/img/icons/booking-head-icon-03.svg" alt="Booking Icon" /></span>
                      <h6>Detail</h6>
                    </li>
                    <li className="active activated">
                      <span><ImageWithBasePath src="assets/img/icons/booking-head-icon-04.svg" alt="Booking Icon" /></span>
                      <h6>Checkout</h6>
                    </li>
                    <li className="active">
                      <span><ImageWithBasePath src="assets/img/icons/booking-head-icon-05.svg" alt="Booking Icon" /></span>
                      <h6>Booking Confirmed</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-card" id="invoice">
            <div className="success-book">
              <span className="success-icon">
                <i className="fa-solid fa-check-double" />
              </span>
              <h5>Thank you! Your Order has been Recieved</h5>
            </div>
            <div className="booking-header">
            <div className="booking-img-wrap">
              <div className="book-img">
                {reservationData.car.images && reservationData.car.images.length > 0 ? (
                  <img
                    src={`${BASE_URL}/api/uploads/${reservationData.car.images[0]}`}
                    alt="Car"
                  />
                ) : (
                  <img src="/assets/img/car-placeholder.jpg" alt="Car" />
                )}
              </div>
              <div className="book-info">
                <h6>{reservationData.car.name}</h6>
                <p>
                  <i className="feather icon-map-pin" /> Location: {reservationData.car.location}
                </p>
              </div>
            </div>
            <div className="book-amount">
              <p>Total Amount</p>
              <h6>{reservationData.totalPrice} MAD</h6>
            </div>
          </div>

            <div className="row">
              {/* Car Pricing */}
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="book-card flex-fill">
                  <div className="book-head">
                    <h6>Car Pricing</h6>
                  </div>
                  <div className="book-body">
                    <ul className="pricing-lists">
                      <li>
                        <div>
                          <p>
                            Price per day <span>({numberOfDays} days)</span>
                          </p>
                          <p className="text-danger">
                            {reservationData.car.pricePerDay} MAD per day
                          </p>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>
                            Number of days <span>({numberOfDays} days)</span>
                          </p>
                          <p className="text-danger">
                            {reservationData.car.pricePerDay * numberOfDays} MAD
                          </p>
                        </div>
                      </li>
                      <li className="total">
                        <p>Subtotal</p>
                        <span>{reservationData.totalPrice} MAD</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Car Pricing */}

              {/* /Car Pricing */}
              {/* Location & Time */}
              {/* Location & Time */}
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="book-card flex-fill">
                  <div className="book-head">
                    <h6>Location &amp; Time</h6>
                  </div>
                  <div className="book-body">
                    <ul className="location-lists">
                      <li>
                        <h6>Rental Type</h6>
                        <p>{reservationData.rentalType === 'pickup' ? 'Self Pickup' : 'Delivery'}</p>
                      </li>
                      <li>
                        <h6>Pickup</h6>
                        <p>
                          {reservationData.rentalType === 'pickup'
                            ? reservationData.car.location
                            : reservationData.deliveryLocation}
                        </p>
                        <p>{new Date(reservationData.startDate).toLocaleString()}</p>
                      </li>
                      <li>
                        <h6>Return</h6>
                        <p>
                          {reservationData.sameReturnLocation
                            ? reservationData.rentalType === 'pickup'
                              ? reservationData.car.location
                              : reservationData.deliveryLocation
                            : reservationData.returnLocation}
                        </p>
                        <p>{new Date(reservationData.returnDate).toLocaleString()}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* /Location & Time */}
              
              {/* Billing Information */}
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="book-card flex-fill">
                  <div className="book-head">
                    <h6>Billing Information</h6>
                  </div>
                  <div className="book-body">
                    <ul className="billing-lists">
                      <li>{`${reservationData.firstName} ${reservationData.lastName}`}</li>
                      <li>{reservationData.address}</li>
                      <li>{`${reservationData.city}, ${reservationData.country}`}</li>
                      <li>{reservationData.phoneNumber}</li>
                      <li>{reservationData.email}</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Payment  Details */}
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="book-card flex-fill">
                  <div className="book-head">
                    <h6>Payment  Details</h6>
                  </div>
                  <div className="book-body">
                    <ul className="location-lists">
                      <li>
                        <h6>Payment Mode</h6>
                        <p>Debit Card</p>
                      </li>
                      <li>
                        <h6>Transaction ID</h6>
                        <p><span>#13245454455454</span></p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Payment  Details */}
              {/* Additional Information */}
              <div className="col-lg-12">
                <div className="book-card mb-0">
                  <div className="book-head">
                    <h6>Additional Information</h6>
                  </div>
                  <div className="book-body">
                    <ul className="location-lists">
                      <li>
                        <p>Rental companies typically require customers to return the vehicle with a full tank of fuel. If the vehicle is returned with less than a full tank, customers may be charged for refueling the vehicle at a premium rate, often higher than local fuel prices.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Additional Information */}
            </div>
          </div>
          <div className="print-btn text-center">
            <button onClick={handlePrint} className="btn btn-secondary">
              Print Order
            </button>
          </div>

        </div>
      </div>
      {/* /Booking Success */}
     
    </div>
    
  )
}

export default BookingSuccess