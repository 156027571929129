import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // or useNavigate if using react-router-dom v6
import { Calendar } from 'primereact/calendar';
import { toast } from 'react-toastify';
// import './style.css';
const SearchSection = () => {
  const [city, setCity] = useState('');
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const navigate = useNavigate(); // useNavigate() if using react-router-dom v6

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!city) {
      toast('Please select a city.');
      return;
    }

    const queryParams = new URLSearchParams();
    queryParams.append('city', city);

    if (date1) {
      queryParams.append('startDate', formatDateForAPI(date1));
    }
    if (date2) {
      queryParams.append('returnDate', formatDateForAPI(date2));
    }

    // Use navigate with the full URL string
    navigate(`/listings/listing-grid?${queryParams.toString()}`);
  };

  // Function to format date as YYYY-MM-DD
  const formatDateForAPI = (date) => {
    return date.toISOString().split('T')[0];
  };

  return (
    <div className="container">
      <div className="search-box-banner">
        <form onSubmit={handleSubmit}>
          <ul className="align-items-center">
            <li className="column-group-main">
              <div className="input-block">
                <label>Pickup City</label>
                <div className="group-img">
                  <select
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a City
                    </option>
                    <option value="Casablanca">Casablanca</option>
                    <option value="Rabat">Rabat</option>
                    <option value="Fes">Fes</option>
                      <option value="Marrakech">Marrakech</option>
                      <option value="Tangier">Tangier</option>
                      <option value="Agadir">Agadir</option>
                      <option value="Meknes">Meknes</option>
                      <option value="Oujda">Oujda</option>
                      <option value="Kenitra">Kenitra</option>
                      <option value="Tetouan">Tetouan</option>
                      <option value="Safi">Safi</option>
                      <option value="Temara">Temara</option>
                      <option value="Mohammedia">Mohammedia</option>
                      <option value="Khouribga">Khouribga</option>
                      <option value="El Jadida">El Jadida</option>
                      <option value="Nador">Nador</option>
                      <option value="Beni Mellal">Beni Mellal</option>
                      <option value="Khemisset">Khemisset</option>
                      <option value="Ksar El Kebir">Ksar El Kebir</option>
                      <option value="Larache">Larache</option>
                      <option value="Guelmim">Guelmim</option>
                      <option value="Berrechid">Berrechid</option>
                      <option value="Khenifra">Khenifra</option>
                      <option value="Berkane">Berkane</option>
                      <option value="Taourirt">Taourirt</option>
                      <option value="Sefrou">Sefrou</option>
                      <option value="Settat">Settat</option>
                      <option value="Salé">Salé</option>
                      <option value="Sidi Kacem">Sidi Kacem</option>
                      <option value="Youssoufia">Youssoufia</option>
                      <option value="Ouarzazate">Ouarzazate</option>
                      <option value="Taza">Taza</option>
                      <option value="Errachidia">Errachidia</option>
                      <option value="Oulad Teima">Oulad Teima</option>
                      <option value="Tiznit">Tiznit</option>
                      <option value="El Kelaa des Sraghna">El Kelaa des Sraghna</option>
                      <option value="Essaouira">Essaouira</option>
                      <option value="Chefchaouen">Chefchaouen</option>
                      <option value="Taroudant">Taroudant</option>
                      <option value="Oued Zem">Oued Zem</option>
                      <option value="Tifelt">Tifelt</option>
                      <option value="Ben Guerir">Ben Guerir</option>
                      <option value="Fkih Ben Salah">Fkih Ben Salah</option>
                      <option value="Sidi Slimane">Sidi Slimane</option>
                      <option value="Tarfaya">Tarfaya</option>
                    {/* Add other city options here */}
                  </select>
                </div>
              </div>
            </li>
            <li className="column-group-main">
              <div className="input-block">
                <label>Pickup Date</label>
                <div className="group-img"
               >
                  <Calendar
                    value={date1}
                    onChange={(e) => setDate1(e.value)}
                    placeholder="Select Pickup Date"
                    style={{ width: '100%' }} 
                    minDate={new Date()}
                  />
                  <span >
                    <i className="feather icon-calendar"></i>
                  </span>
                </div>
              </div>
            </li>
            
            <li className="column-group-main">
              <div className="input-block">
                <label>Return Date</label>
                <div className="group-img" style={{ width: '100%' }} >
                  <Calendar
                    value={date2}
                    onChange={(e) => setDate2(e.value)}
                    placeholder="Select Return Date"
                    style={{ width: '100%' }}
                    inputStyle={{ width: '100%' }} 
                    minDate={date1 || new Date()}
                  />
                  <span>
                    <i className="feather icon-calendar"></i>
                  </span>
                </div>
              </div>
            </li>
            <li className="column-group-last">
              <div className="input-block">
                <button className="btn search-button" type="submit">
                  <i className="fa fa-search" aria-hidden="true" /> Search
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
};

export default SearchSection;
