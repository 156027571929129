import React, { useEffect, useState } from "react";
import Breadcrumbs from "../common/breadcrumbs";
import Aos from "aos";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import DashboardMenu from "./common/dashboard-menu";
import { Link } from "react-router-dom";
import axiosInstance from '../../core/services/axiosInstance';
import { useAuth } from '../../core/context/AuthContext';
import BASE_URL from '../../config'; 
import io from 'socket.io-client';

const UserMessages = () => {
  const [openChat, setOpenChat] = useState(false);
  const socket = io(BASE_URL);
  const chatOpen = () => {
    setOpenChat(!openChat);
  };

  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  // conversation setup 
  const { isAuthenticated } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
console.log(conversations ,setCurrentConversationId) ;

  // Fetch conversations when the component mounts
  useEffect(() => {
    if (isAuthenticated) {
      fetchConversations();
    }
  }, [isAuthenticated]);

  // Fetch messages when a conversation is selected
  const fetchConversations = async () => {
    try {
      const response = await axiosInstance.get('/api/conversations');
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  // Handle incoming messages via Socket.IO
  useEffect(() => {
    socket.on('receive_message', (message) => {
      if (message.conversation === currentConversationId) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    // Cleanup on component unmount
    return () => {
      socket.off('receive_message');
    };
  }, [currentConversationId]);
 
  const fetchMessages = async (conversationId) => {
    try {
      const response = await axiosInstance.get(`/api/conversations/${conversationId}/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };
  console.log(fetchMessages)
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
  
    try {
      const response = await axiosInstance.post(
        `/api/conversations/${currentConversationId}/messages`,
        { content: newMessage }
      );
      setMessages((prevMessages) => [...prevMessages, response.data]);
      setNewMessage('');
  
      // Emit the message via Socket.IO
      socket.emit('send_message', response.data);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  const supportAgentId = '674a0f9552a1ed0414bfd586';
  const startSupportConversation = async () => {
    try {
      console.log('Starting support conversation. Current conversations:', conversations);
  
      // Check if a conversation with support already exists
      const existingConversation = conversations.find((conversation) =>
        conversation.participants.some((participant) => {
          const participantIdStr = participant.participant.toString();
          return (
            participant.participantModel === 'user' &&
            participantIdStr === supportAgentId
          );
        })
      );
  
      if (existingConversation) {
        console.log('Found existing conversation:', existingConversation._id);
        setCurrentConversationId(existingConversation._id);
        // Fetch messages for the existing conversation
        fetchMessages(existingConversation._id);
      } else {
        console.log('No existing conversation found. Creating new one.');
        // Create a new conversation with the support team
        const response = await axiosInstance.post('/api/conversations', {
          participantModel: 'user',
          participantId: supportAgentId,
        });
        // Fetch updated conversations
        await fetchConversations();
        setCurrentConversationId(response.data._id);
        // Fetch messages for the new conversation
        fetchMessages(response.data._id);
      }
    } catch (error) {
      console.error(
        'Error starting support conversation:',
        error.response ? error.response.data : error.message
      );
    }
  };
  console.log(startSupportConversation)
  return (
    <>
      <Breadcrumbs title="User Message" subtitle="User Message" />
      <DashboardMenu />
      <div className="content content-chat top-space-chat">
        <div className="container">
          {/* Content Header */}
          <div className="content-header">
            <h4>Messages</h4>
          </div>
          {/* /Content Header */}
          <div className={`row chat-window ${openChat ? 'chat-slide' : ''}`}>
            <div className="col-xl-12">
              <div className={`chat-window ${openChat ? 'chat-slide' : ''}`}>
                {/* Chat Left */}
                <div className="chat-cont-left">
                  <div className="chat-header">
                    <span>Chats</span>
                   
                  </div>
                 
                  <div className="chat-users-list">
                    <div className="chat-scroll">
                      <Link
                        to="#"
                        className={`notify-block read-chat d-flex open-chat ${
                          currentConversationId ? '' : 'active'
                        }`}
                        onClick={startSupportConversation}
                      >
                        <div className="media-img-wrap flex-shrink-0">
                          <div className="avatar avatar-online">
                            <ImageWithBasePath
                              src="assets/img/profiles/support-avatar.png"
                              alt="User Image"
                              className="avatar-img rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="media-body chat-custom flex-grow-1">
                          <div>
                            <div className="user-name">Support Team</div>
                          </div>
                          <div>
                            <div className="last-chat-time block">Start a conversation</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>


                </div>
                {/* /Chat Left */}
                {/* Chat Right */}
                <div className="chat-cont-right">
                  <div className="chat-header">
                    <Link onClick={chatOpen}
                      id="back_user_list"
                      to="#"
                      className="back-user-list"
                    >
                      <i className="feather icon-chevron-left" />
                    </Link>
                    <div className="notify-block d-flex">
                      <div className="media-img-wrap flex-shrink-0">
                        <div className="avatar avatar-online">
                        <ImageWithBasePath
                            src="assets/img/profiles/support-avatar.png"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="user-name">Support team</div>
                        <div className="user-status">online</div>
                      </div>
                    </div>
                    <div className="chat-options">
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#voice_call"
                      >
                        <i className="feather icon-phone" />
                      </Link>
                    
                    </div>
                  </div>
                  <div className="chat-body">
                    <div className="chat-scroll">
                      <ul className="list-unstyled">
                      {messages.map((message) => (
                          <li
                            key={message._id}
                            className={`notify-block d-flex ${
                              message.senderModel === 'client' ? 'sent' : 'received'
                            }`}
                          >
                            {message.senderModel === 'user' && (
                              <div className="avatar flex-shrink-0">
                               <ImageWithBasePath
                            src="assets/img/profiles/support-avatar.png"
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                              </div>
                            )}
                            <div className="media-body flex-grow-1">
                              <div className="msg-box">
                                <div>
                                  <p>{message.content}</p>
                                  <ul className="chat-msg-info">
                                    <li>
                                      <div className="chat-time">
                                        <span>
                                          {new Date(message.createdAt).toLocaleTimeString()}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                        
                      </ul>
                    </div>
                  </div>
                  <div className="chat-footer">
                    <div className="input-group">
                      <input
                        type="text"
                        className="input-msg-send form-control rounded-pill"
                        placeholder="Type something"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSendMessage();
                          }
                        }}
                      />
                      <button
                        type="button"
                        className="btn msg-send-btn rounded-pill ms-2"
                        onClick={handleSendMessage}
                      >
                        <i className="fa-solid fa-paper-plane" />
                      </button>
                    </div>
                  </div>

                </div>
                {/* /Chat Right */}
              </div>
            </div>
          </div>
          {/* /Row */}
        </div>
      </div>

      {/* Voice Call Modal */}
      <div className="modal fade call-modal" id="voice_call">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {/* Outgoing Call */}
              <div className="call-box incoming-box">
                <div className="call-wrapper">
                  <div className="call-inner">
                    <div className="call-user">
                      <ImageWithBasePath
                        alt="User Image"
                        src="assets/img/profiles/avatar-08.jpg"
                        className="call-avatar"
                      />
                      <h4>Darren Elder</h4>
                      <span>Connecting...</span>
                    </div>
                    <div className="call-items">
                      <Link
                        to="#"
                        className="btn call-item call-end"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="feather icon-phone-off" />
                      </Link>
                      <Link
                        to="#"
                        className="btn call-item call-start"
                      >
                        <i className="feather icon-phone" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Outgoing Call */}
            </div>
          </div>
        </div>
      </div>
      {/* /Voice Call Modal */}
      {/* Video Call Modal */}
      <div className="modal fade call-modal" id="video_call">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {/* Incoming Call */}
              <div className="call-box incoming-box">
                <div className="call-wrapper">
                  <div className="call-inner">
                    <div className="call-user">
                      <ImageWithBasePath
                        className="call-avatar"
                        src="assets/img/profiles/avatar-08.jpg"
                        alt="User Image"
                      />
                      <h4>Darren Elder</h4>
                      <span>Calling ...</span>
                    </div>
                    <div className="call-items">
                      <Link
                        to="#"
                        className="btn call-item call-end"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="feather icon-phone-off" />
                      </Link>
                      <Link
                        to="#"
                        className="btn call-item call-start"
                      >
                        <i className="feather icon-video" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Incoming Call */}
            </div>
          </div>
        </div>
      </div>
      {/* Video Call Modal */}
    </>
  );
};

export default UserMessages;
