// src/core/context/AuthContext.tsx
// This file defines an AuthContext for managing user authentication state in a React application.
// It provides methods for logging in, registering, and logging out users, along with storing 
// the authenticated client's information. The AuthProvider component initializes the authentication 
// state based on stored data and provides the authentication context to its children. 
// A custom hook, `useAuth`, is also exported for easy access to the authentication context 
import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import AuthService from '../services/authService'; // Adjust the import path if necessary

interface Client {
  clientId: string;
  clientname: string;
  email: string;
  // Add other client properties as needed
}

interface AuthContextProps {
  isAuthenticated: boolean;
  client: Client | null;
  login: (email: string, password: string) => Promise<void>;
  register: (clientname: string, email: string, password: string) => Promise<void>;
  logout: () => void;
}

// Provide default implementations that throw errors
export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  client: null,
  login: async () => {
    throw new Error("AuthContext: `login` method not implemented.");
  },
  register: async () => {
    throw new Error("AuthContext: `register` method not implemented.");
  },
  logout: () => {
    throw new Error("AuthContext: `logout` method not implemented.");
  },
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [client, setClient] = useState<Client | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const storedClient = AuthService.getUser();
    const token = localStorage.getItem('jwt_token');
    if (storedClient && token) {
      setClient(storedClient);
      setIsAuthenticated(true);
      AuthService.setSession(token); // Ensure axiosInstance has the token set
    }
  }, []);
  const login = async (email: string, password: string) => {
    const data = await AuthService.login(email, password);
    setClient(data.client);
    setIsAuthenticated(true);
  };
  

  const register = async (clientname: string, email: string, password: string) => {

      const data = await AuthService.register(clientname, email, password);
      setClient(data.client);
      setIsAuthenticated(true);
   
  };

  const logout = () => {
    AuthService.logout();
    setClient(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        client,
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
