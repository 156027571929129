import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../common/breadcrumbs";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Dropdown } from "primereact/dropdown";
import axiosInstance from '../../core/services/axiosInstance';
import { Helmet } from 'react-helmet';
import Sliders from "rc-slider";
import "rc-slider/assets/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { all_routes } from "../router/all_routes";
import BASE_URL from '../../config';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import SearchSection from "../common/search";
import { useNavigate , useParams } from 'react-router-dom';
import { useAuth } from '../../core/context/AuthContext';
  import { useLocation } from 'react-router-dom';
const ListingGrid = () => {
  const navigate = useNavigate(); // For programmatic navigation
  const routes = all_routes;  
  const [hoveredPage, setHoveredPage] = useState<number | null>(null);
  // const [selectedNumber, setSelectedNumber] = useState(null);
  const { isAuthenticated } = useAuth(); 
  const [currentPage, setCurrentPage] = useState(1);
  const [carsPerPage, setCarsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedSort, setSelectedSort] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedFuelType, setSelectedFuelType] = useState('');
  const [minPrice] = useState('');
  const [maxPrice] = useState('');
  const { categorySlug } = useParams();
  

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const city = queryParams.get('city');
const startDate = queryParams.get('startDate');
const returnDate = queryParams.get('returnDate');

const [cars, setCars] = useState([]);

useEffect(() => {
  if (city) {
    fetchAvailableCars(city, startDate, returnDate);
  }else if (categorySlug) {
    fetchCarsByCategory(categorySlug);
  }
  else {
    fetchAllCars();
  }
}, [
  city,
  startDate,
  returnDate,
  categorySlug,
  currentPage,
  carsPerPage,
  selectedSort,
  sortOrder,
  selectedFuelType,
  minPrice,
  maxPrice,
]);

const fetchAvailableCars = async (city, startDate, returnDate) => {
  try {
    const params = {
      city,
      page: currentPage,
      limit: carsPerPage,
      sortBy: selectedSort,
      order: sortOrder,
    };

    if (startDate) params.startDate = startDate;
    if (returnDate) params.returnDate = returnDate;
    if (selectedFuelType) {
      params.fuelType = selectedFuelType;
    }
    if (minPrice !== '') {
      params.minPrice = minPrice;
    }
    if (maxPrice !== '') {
      params.maxPrice = maxPrice;
    }

    const response = await axios.get(`${BASE_URL}/api/client/available-cars`, { params });
    setCars(response.data.cars);
    setTotalPages(response.data.totalPages);
  } catch (error) {
    toast.error('Error fetching available cars');
  }
};
// fetch cars by category slog parametre 



const fetchCarsByCategory = async (categorySlug) => {
  try {
    const params = {
      page: currentPage,
      limit: carsPerPage,
      sortBy: selectedSort,
      order: sortOrder,
      ...(selectedFuelType && { fuelType: selectedFuelType }),
      ...(minPrice && { minPrice }),
      ...(maxPrice && { maxPrice }),
    };

    const response = await axios.get(`${BASE_URL}/api/category/${categorySlug}`, { params });
    setCars(response.data.cars);
    setTotalPages(response.data.totalPages);
  } catch (error) {
    toast.error('Error fetching cars by category');
  }
};


const fetchAllCars = async () => {
  try {
    const params = {
      page: currentPage,
      limit: carsPerPage,
      sortBy: selectedSort,
      order: sortOrder,
    };

    if (selectedFuelType) {
      params.fuelType = selectedFuelType;
    }
    if (minPrice !== '') {
      params.minPrice = minPrice;
    }
    if (maxPrice !== '') {
      params.maxPrice = maxPrice;
    }

    const response = await axios.get(`${BASE_URL}/api/client/cars`, { params });
    setCars(response.data.cars);
    setTotalPages(response.data.totalPages);
  } catch (error) {
    toast.error('There was an error fetching the cars!');
  }
};


const handleRentNow = (carId: string) => {
  console.log("Car ID for future use:", carId); // Placeholder for future logic

  if (isAuthenticated) {
    
    navigate(`/pages/booking-checkout/${carId}`);
// Example of passing carId in state
  } else {
    // Redirect to /authentication/login if not authenticated
    navigate('/authentication/login');
  }
};



  const numberOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
    { label: "30", value: 30 },
  ];
  const sortOptions = [
    { label: "Newest", value: { sortBy: 'createdAt', order: 'desc' } },
    { label: "Cheapest", value: { sortBy: 'pricePerDay', order: 'asc' } },
    { label: "Low to High", value: { sortBy: 'pricePerDay', order: 'asc' } },
    { label: "High to Low", value: { sortBy: 'pricePerDay', order: 'desc' } },
    { label: "Best Rated", value: { sortBy: 'averageRating', order: 'desc' } },
  ];
  

  const settings1 = {
    dots: true,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [wishlistCars, setWishlistCars] = useState([]);

  const [wishlist, setWishlist] = useState([]);
  console.log(wishlistCars);
useEffect(() => {
  if (isAuthenticated) {
    const fetchWishlistCars = async () => {
      try {
        const response = await axiosInstance.get('/api/wishlist');
        setWishlistCars(response.data);
        // Store wishlist car IDs for easy lookup
        setWishlist(response.data.map((car) => car._id));
      } catch (error) {
        console.error('Error fetching wishlist cars:', error);
      }
    };

    fetchWishlistCars();
  } else {
    // Clear wishlist data if not authenticated
    setWishlistCars([]);
    setWishlist([]);
  }
}, [isAuthenticated]);

const handleWishlistClick = async (carId) => {
  if (!isAuthenticated) {
    // Redirect to register page
    navigate('/authentication/register');
    return;
  }

  try {
    if (wishlist.includes(carId)) {
      // Remove from wishlist
      await axiosInstance.delete(`/api/wishlist/${carId}`);
      setWishlist((prevWishlist) => prevWishlist.filter((id) => id !== carId));
      toast.error('Removed from the WishList successfully!');


    } else {
      // Add to wishlist
      await axiosInstance.post(`/api/wishlist/${carId}`);
      setWishlist((prevWishlist) => [...prevWishlist, carId]);
      toast.success('Added to the WishList successfully!');


    }
  } catch (error) {
    console.error('Error updating wishlist:', error);
  }
};
  return (
    <div className="listing-page">
      <Breadcrumbs title="Car Listings" subtitle="Listings" />
      {/* Search */}
      <Helmet>
        <title>Browse Car Rentals - Find Your Perfect Ride | CarDND</title>
        <meta name="description" content="Explore our extensive car rental fleet in an easy-to-view grid layout. Find your perfect ride today at CardND!" />
        <link rel="canonical" href="https://cardnd.com/listings/listing-grid" />
      </Helmet>
      <div className="section-search page-search">
      <SearchSection />
        
      </div>
      {/* /Search */}
      {/* Sort By */}
      <div className="sort-section">
        <div className="container">
          <div className="sortby-sec">
            <div className="sorting-div">
              <div className="row d-flex align-items-center">
                <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
                  <div className="count-search">
                    <p>Showing 154 Car</p>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-9 col-sm-12 col-12">
                  <div className="product-filter-group">
                    <div className="sortbyset">
                      <ul className="d-flex">
                        <li>
                          <span className="sortbytitle">Show : </span>
                          <div className="sorting-select select-one">
                          <Dropdown
                              value={carsPerPage}
                              onChange={(e) => {
                                setCarsPerPage(e.value);
                                setCurrentPage(1); // Reset to first page when changing items per page
                              }}
                              options={numberOptions}
                              optionLabel="label"
                              placeholder="Show"
                            />
                          </div>
                        </li>
                        <li>
                          <span className="sortbytitle">Sort By </span>
                          <div className="sorting-select select-two">
                            <Dropdown
                                value={selectedSort}
                                onChange={(e) => {
                                  setSelectedSort(e.value.sortBy);
                                  setSortOrder(e.value.order);
                                  setCurrentPage(1); // Reset to first page on sort change
                                }}
                                options={sortOptions}
                                optionLabel="label"
                                placeholder="Sort By"
                              />

                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="grid-listview">
                      <ul>
                        <li>
                          <Link to={routes.listingGrid} className="active">
                            <i className="feather icon-grid" />
                          </Link>
                        </li>
                        <li>
                          <Link to={routes.listingList}>
                            <i className="feather icon-list" />
                          </Link>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Sort By */}
      {/* Car Grid View */}
      <section className="section car-listing pt-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-12 col-12 theiaStickySidebar">
              <div className="stickybar">
                <form action="#" autoComplete="off" className="sidebar-form">
                  <div className="sidebar-heading">
                    <h3>What Are You Looking For</h3>
                  </div>
                  <div className="product-search">
                    
                  </div>
                
                  <div className="accord-list">
                    
                    <div className="accordion" id="accordionMain2">
                      <div className="card-header-new" id="headingTwo">
                        <h6 className="filter-title">
                          <Link
                            to="#"
                            className="w-100 collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            Car Category
                            <span className="float-end">
                              <i className="fa-solid fa-chevron-down" />
                            </span>
                          </Link>
                        </h6>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample2"
                      >
                        <div className="card-body-chat">
                          <div id="checkBoxes2">
                            <div className="selectBox-cont">
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Convertible (25)
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Coupe (15)
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Sedan (10)
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> EV (5)
                              </label>
                              {/* View All */}
                              <div className="view-content">
                                <div className="viewall-One">
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Hatchback
                                    (123)
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Luxury (06)
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> SUV (6)
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Wagon (5)
                                  </label>
                                </div>
                              </div>
                              {/* /View All */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="accordion" id="accordionMain04">
                      <div className="card-header-new" id="headingfuel">
                        <h6 className="filter-title">
                          <Link
                            to="#"
                            className="w-100 collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefuel"
                            aria-expanded="true"
                            aria-controls="collapsefuel"
                          >
                            Fuel Type
                            <span className="float-end">
                              <i className="fa-solid fa-chevron-down" />
                            </span>
                          </Link>
                        </h6>
                      </div>
                      <div
                        id="collapsefuel"
                        className="collapse"
                        aria-labelledby="headingfuel"
                        data-bs-parent="#accordionExample2"
                      >
                        <div className="card-body-chat">
                          <div className="fuel-list">
                            <ul>
                              <li>
                                <div className="input-selection">
                                  <input
                                    type="radio"
                                    name="fuelType"
                                    id="Electric"
                                    value="Electric"
                                    onChange={(e) => setSelectedFuelType(e.target.value)}
                                  />
                                  <label htmlFor="Electric">Electric</label>
                                </div>
                              </li>
                              <li>
                                <div className="input-selection">
                                  <input
                                    type="radio"
                                    name="fuelType"
                                    id="Hybrid"
                                    value="Hybrid"
                                    onChange={(e) => setSelectedFuelType(e.target.value)}
                                  />
                                  <label htmlFor="Hybrid">Hybrid</label>
                                </div>
                              </li>
                              <li>
                                <div className="input-selection">
                                  <input
                                    type="radio"
                                    name="fuelType"
                                    id="Diesel"
                                    value="Diesel"
                                    onChange={(e) => setSelectedFuelType(e.target.value)}
                                  />
                                  <label htmlFor="Diesel">Diesel</label>
                                </div>
                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="accordion" id="accordionMain8">
                      <div className="card-header-new" id="headingThree">
                        <h6 className="filter-title">
                          <Link
                            to="#"
                            className="w-100 collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                          >
                            Capacity
                            <span className="float-end">
                              <i className="fa-solid fa-chevron-down" />
                            </span>
                          </Link>
                        </h6>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample3"
                      >
                        <div className="card-body-chat">
                          <div id="checkBoxes3">
                            <div className="selectBox-cont">
                              <label className="custom_check w-100">
                                <input type="checkbox" name="bystatus" />
                                <span className="checkmark" /> 2 Seater
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="bystatus" />
                                <span className="checkmark" /> 4 Seater
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="bystatus" />
                                <span className="checkmark" /> 5 Seater
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="bystatus" />
                                <span className="checkmark" /> 7 Seater
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion" id="accordionMain9">
                      <div className="card-header-new" id="headingFour">
                        <h6 className="filter-title">
                          <Link
                            to="#"
                            className="w-100 collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour"
                          >
                            Price
                            <span className="float-end">
                              <i className="fa-solid fa-chevron-down" />
                            </span>
                          </Link>
                        </h6>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample4"
                      >
                        <div className="card-body-chat">
                          <div className="filter-range">
                            <Sliders
                              className="input-range"
                              min={0}
                              max={100}
                              defaultValue={[0, 50]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion" id="accordionMain4">
                      <div className="card-header-new" id="headingtransmiss">
                        <h6 className="filter-title">
                          <Link
                            to="#"
                            className="w-100 collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsetransmission"
                            aria-expanded="true"
                            aria-controls="collapsetransmission"
                          >
                            Transmission
                            <span className="float-end">
                              <i className="fa-solid fa-chevron-down" />
                            </span>
                          </Link>
                        </h6>
                      </div>
                      <div
                        id="collapsetransmission"
                        className="collapse"
                        aria-labelledby="headingtransmiss"
                        data-bs-parent="#accordionExample2"
                      >
                        <div className="card-body-chat">
                          <div className="fuel-list">
                            <ul>
                              <li>
                                <div className="input-selection">
                                  <input
                                    type="radio"
                                    name="transmission"
                                    id="manual"
                                    defaultChecked
                                  />
                                  <label htmlFor="manual">Manual </label>
                                </div>
                              </li>
                              <li>
                                <div className="input-selection">
                                  <input
                                    type="radio"
                                    name="transmission"
                                    id="semi"
                                  />
                                  <label htmlFor="semi">Semi Automatic</label>
                                </div>
                              </li>
                              <li>
                                <div className="input-selection">
                                  <input
                                    type="radio"
                                    name="transmission"
                                    id="automatic"
                                  />
                                  <label htmlFor="automatic">Automatic</label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                  </div>
                  <button
                    type="submit"
                    className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary filter-btn"
                  >
                    <span>
                      <i className="feather icon-filter me-2" />
                    </span>
                    Filter results
                  </button>
                  <Link to="#" className="reset-filter">
                    Reset Filter
                  </Link>
                </form>
              </div>
            </div>

















            <div className="col-lg-9">
              <div className="row">
                {/*  car card  */}
                {cars && cars.length > 0 ? (  
                  cars.map((car) => (
                <div   key={car._id}className="col-xxl-4 col-lg-6 col-md-6 col-12">
               
                  <div className="listing-item">
                    <div className="listing-img">
                      <div className="img-slider listing-page-slider">
                        <Slider {...settings1}>
                          {car.images && car.images.length > 0 ? (
                            car.images.map((image, index) => (
                              <div key={index} className="slide-images">
                                <Link to={`${routes.listingDetails}/${car._id}`}>
                                  <img
                                    src={`${BASE_URL}/api/uploads/${image}`} // Using dynamic path for images
                                    className="img-fluid"
                                    alt={car.name}
                                  />
                                </Link>
                              </div>
                            ))
                          ) : (
                            <div>No Images Available</div>
                          )}
                        </Slider>
                      </div>

                      <div className="fav-item justify-content-end">
                        {/* <span className="img-count">
                          <i className="feather icon-image" />
                          04
                        </span> */}
                        <button
                              className={`fav-icon ${wishlist.includes(car._id) ? 'selected' : ''}`}
                              onClick={() => handleWishlistClick(car._id)}
                            >
                              <i className="feather icon-heart" />
                            </button>
                      </div>
                      <span className="featured-text">{car.name}</span>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features d-flex align-items-end justify-content-between">
                        <div className="list-rating">
                        
                          <h3 className="listing-title">
                            <Link to={routes.listingDetails}>
                              {car.name}
                            </Link>
                          </h3>
                          <div className="list-rating">
                                {Array.from({ length: 5 }, (_, index) => {
                                  // const ratingValue = index + 1;
                                  const difference = car.averageRating - index;

                                  let starClass = 'far fa-star'; // Empty star

                                  if (difference >= 1) {
                                    // Full star
                                    starClass = 'fas fa-star filled';
                                  } else if (difference >= 0.5) {
                                    // Half star
                                    starClass = 'fas fa-star-half-alt filled';
                                  }

                                  return <i key={index} className={starClass} />;
                                })}
                                <span>
                                  ({car.averageRating}) {car.totalReviews} Reviews
                                </span>
                              </div>
                        </div>
                        {/* <div className="list-km">
                          <span className="km-count">
                            <ImageWithBasePath
                              src="assets/img/icons/map-pin.svg"
                              alt="author"
                            />
                            3.2m
                          </span>
                        </div> */}
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>{car.transmission}</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>{car.mileage}KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Petrol"
                              />
                            </span>
                            <p>{car.fuelType}</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Power</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt='2018'
                              />
                            </span>
                            <p>{car.registrationYear}</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Persons"
                              />
                            </span>
                            <p>{car.seats}Persons</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="feather icon-map-pin" />
                          </span>
                          {car.city}
                        </div>
                        <div className="listing-price">
                          <h6>
                          MAD {car.pricePerDay} <span>/ Day</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                              <button
                                className="btn btn-order w-100"
                                
                                onClick={() => handleRentNow(car._id)}
                              >
                                <i className="feather icon-calendar me-2" ></i>
                                Rent Now
                              </button>
                            </div>
                    </div>
                    <div className="feature-text">
                      <span className="bg-danger">Featured</span>
                    </div>
                  </div>
                  
                </div>
                  ))
                ) : (
                  <div className="no-results">
                    <p>No cars available for the selected criteria.</p>
                    <p>Please adjust your search parameters.</p>
                  </div>
                )}
                  <ToastContainer />
                {/* /col */}
                
              </div>


























              {/*Pagination*/}
              {/* Pagination */}
              <div className="blog-pagination">
                <nav>
                ;

<ul className="pagination page-item justify-content-center">
  <li className={`previtem ${currentPage === 1 ? 'disabled' : ''}`}>
    <Link
      to={`?page=${Math.max(currentPage - 1, 1)}`}
      className="page-link"
      style={{ color: '#C09465' }}
      onClick={(e) => {
        e.preventDefault(); // Prevent default link behavior if you handle page change with a function
        setCurrentPage((prev) => Math.max(prev - 1, 1));
      }}
    >
      <i className="fas fa-regular fa-arrow-left me-2" /> Prev
    </Link>
  </li>&nbsp;

  {Array.from({ length: totalPages }, (_, index) => {
    const pageNum = index + 1;
    const isActive = currentPage === pageNum;  
     const isHovered = hoveredPage === pageNum;

    return (
      <li 
  key={pageNum} 
  className={`page-item ${isActive ? 'active' : ''}`} 
  style={{ margin: '0 5px' }} // Adds horizontal space between buttons
>
  <Link
    to={`?page=${pageNum}`}
    className="page-link"
    style={{
      color: isActive ? 'white' : isHovered ? 'white' : '#127384',
      backgroundColor: isActive ? '#127384' : isHovered ? '#127384' : 'white',
      padding: '8px 12px', // Optional: Adjust padding for button size
      borderRadius: '4px', // Optional: Border radius for rounded corners
      border: isActive ? '2px solid #127384 ' : '1px solid #ced4da', // Active border style
      display: 'inline-block',
      transition: 'background-color 0.3s ease', // Smooth background transition on hover
    }}
    onMouseEnter={() => setHoveredPage(pageNum)}
    onMouseLeave={() => setHoveredPage(null)}
    onClick={(e) => {
      e.preventDefault();
      setCurrentPage(pageNum);
    }}
  >
    {pageNum}
  </Link>
</li>

    );
  })}
&nbsp;
  <li className={`nextlink ${currentPage === totalPages ? 'disabled' : ''}`}>
    
    <Link
      to={`?page=${Math.min(currentPage + 1, totalPages)}`}
      className="page-link"
      style={{ color: '#C09465' }}
      onClick={(e) => {
        e.preventDefault(); // Prevent default link behavior if you handle page change with a function
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
      }}
    >
      Next <i className="fas fa-regular fa-arrow-right ms-2" />
    </Link>
  </li>
</ul>

                </nav>
              </div>
              {/* /Pagination */}

              {/*/Pagination*/}
            </div>
          </div>
        </div>
      </section>
      {/* /Car Grid View */}
    </div>
  );
};

export default ListingGrid;
