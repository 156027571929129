import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { set_is_mobile_sidebar } from "../../../core/data/redux/action";

const HeaderTwo = () => {
  const routes = all_routes;
  const [selectedPersons, setSelectedPersons] = useState(null);
  const types = [
    { name: 'Choose Location' },
    { name: 'Newyork' }
  ];
  const bikemodal = [
    { name: 'Catamaran' },
    { name: 'Motor yachts' },
    { name: 'Sailing yachts' }
  ];
  const [SelectedModal, setSelectedModal] = useState(null);
  const location = useLocation();
  const [date1, setDate1] = useState(null);
  
  const bannerimgslideroption = {
    dots: true,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
 
  const [headerClass, setHeaderClass] = useState("header header-two");
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold) {
        setHeaderClass("header header-two header-fixed");
      } else {
        setHeaderClass("header header-two");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array to run this effect only once on component mount
  const mobileSidebarData = useSelector((state: any) => state.mobileSidebar);

  const dispatch = useDispatch();

  const handleMobileSidebar = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebarData));
  };
  return (
    <>
    <div className="hero-sec-main">
      <header  className={headerClass} >
       

        <div className="container">
          <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
              <Link id="mobile_btn" to="#" onClick={handleMobileSidebar}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo">
                <ImageWithBasePath
                  src="assets/img/logo.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo-small">
                <ImageWithBasePath
                  src="assets/img/logo.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routes.homeOne} className="menu-logo">
                  <ImageWithBasePath
                    src="assets/img/logo.png"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link id="menu_close" className="menu-close" to="#">
                  {" "}
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li
                  className={`has-submenu ${location.pathname.includes("index") ? "active" : ""}`}
                >
                  <Link to={routes.homeOne}>
                    Home 
                    {/* <i className="fas fa-chevron-down" /> */}
                  </Link>
                  {/* <ul className="submenu">
                    <li
                      className={
                        location.pathname.includes(routes.homeOne)
                          ? ""
                          : ""
                      }
                    >
                      <Link to={routes.homeOne}>Home 1</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes(routes.homeTwo)
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={routes.homeTwo}>Home 2</Link>
                    </li>
                    <li
                      className={
                        location.pathname.includes(routes.homeThree)
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={routes.homeThree}>Home 3</Link>
                    </li>
                  </ul> */}
                </li>
                <li
                  className={`has-submenu ${location.pathname.includes("listing") ? "active" : ""}`}
                >
                  <Link to={routes.listingList}>
                    Listings
                     {/* <i className="fas fa-chevron-down" /> */}
                  </Link>
                </li>
                  
                
                <li
                  className={`has-submenu ${location.pathname.includes("pages") ? "active" : ""}`}
                >
                  <Link to={routes.aboutUs}>
                  About Us 
                  {/* <i className="fas fa-chevron-down" /> */}
                  </Link>
                 </li> 
                <li
                  className={`has-submenu ${location.pathname.includes("blog") ? "active" : ""}`}
                >
                  <Link to={routes.blogList}>
                    Blog 
                    {/* <i className="fas fa-chevron-down" /> */}
                  </Link>
                  
                </li>
                <li
                  className={
                    location.pathname.includes(routes.contactUs) ? "" : ""
                  }
                >
                  <Link to={routes.contactUs}>Contact</Link>
                </li>
                <li className="login-link">
                  <Link to={routes.register}>Sign Up</Link>
                </li>
                <li className="login-link">
                  <Link to={routes.login}>Sign In</Link>
                </li>
              </ul>
            </div>
            <ul className="nav header-navbar-rht">
              <li className="nav-item">
                <Link className="nav-link login-link" to={routes.login}>
                  <span>
                    <i className="bx bx-user me-2" />
                  </span>
                  Sign In /{" "}
                </Link>
                <Link className="nav-link login-link ms-1" to={routes.register}>
                  Register{" "}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link header-reg" to={routes.listingList}>
                  <span>
                    <i className="bx bx-plus-circle" />
                  </span>
                  Add Listing
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </header>
      <>
  {/* Banner */}
  <section className="banner-section banner-sec-two banner-slider">
    <div className="banner-img-slider">
    <Slider {...bannerimgslideroption} >
      <div className="slider-img">
        <ImageWithBasePath src="assets/img/bg/home-banner-img.png" alt="Img" />
      </div>
      <div className="slider-img">
        <ImageWithBasePath src="assets/img/bg/home-banner-img-02.png" alt="Img" />
      </div>
      <div className="slider-img">
        <ImageWithBasePath src="assets/img/bg/home-banner-img-03.png" alt="Img" />
      </div>
    </Slider>
    </div>
    <div className="container">
      <div className="home-banner">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="hero-sec-contents">
              <div className="banner-title">
                <h1>
                  Online Yacht Booking.
                  <span>Made Simple.</span>
                </h1>
                <p>
                  Modern design sports cruisers for those who crave adventure
                  &amp; grandeur yachts for relaxing with your loved ones. We
                  Offer diverse and fully equipped yachts
                </p>
              </div>
              <div className="banner-form">
                <form >
                  <div className="banner-search-list">
                    <div className="input-block customdropdown">
                      <label>
                        <i className="bx bx-map" />
                        Location
                      </label>
                     
                      <Dropdown
                                value={selectedPersons}
                                onChange={(e) => setSelectedPersons(e.value)}
                                options={types}
                                optionLabel="name"
                                placeholder="Cruiser"
                                className="w-100"
                              />
                    </div>
                    <div className="input-block">
                      <label>
                        <i className="bx bx-calendar" />
                        Pickup Date
                      </label>
                      <div className="date-widget">
                        <div className="group-img customcalendar">
                        <Calendar
                          value={date1}
                          onChange={(e) => setDate1(e.value)}
                          placeholder="04/11/2023"
                        />
                          {/* <input
                            type="text"
                            className="form-control datetimepicker"
                            placeholder="04/11/2023"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="input-block">
                      <label>
                        <i className="bx bx-calendar" />
                        Pickup Date
                      </label>
                      <div className="date-widget">
                        <div className="group-img customcalendar">
                        <Calendar
                          value={date1}
                          onChange={(e) => setDate1(e.value)}
                          placeholder="04/11/2023"
                        />
                          {/* <input
                            type="text"
                            className="form-control datetimepicker"
                            placeholder="04/11/2023"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="input-block customdropdown">
                      <label>
                        <i className="bx bxs-ship" />
                        Yacht Type
                      </label>
                     
                      <Dropdown
                                value={SelectedModal}
                                onChange={(e) => setSelectedModal(e.value)}
                                options={bikemodal}
                                optionLabel="name"
                                placeholder="Catamaran"
                                className="w-100"
                              />
                    </div>
                  </div>
                  <div className="input-block-btn">
                    <button className="btn btn-primary" type="submit">
                      <i className="bx bx-search-alt me-2" /> Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="banner-user-group text-center">
                <ul>
                  <li>
                    <Link to="#">
                      <ImageWithBasePath src="assets/img/profiles/avatar-01.jpg" alt="Img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="Img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <ImageWithBasePath src="assets/img/profiles/avatar-03.jpg" alt="Img" />
                    </Link>
                  </li>
                  <li className="users-text">
                    <h5>6K + Customers</h5>
                    <span>has used our renting services </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-btn text-center">
        <Link to="https://www.youtube.com/embed/ExJZAegsOis" data-fancybox="">
          <span>
            <i className="bx bx-play" />
          </span>
        </Link>
        <h6>Check Our Video</h6>
      </div>
    </div>
  </section>
  {/* /Banner */}
</>

    </div>
    </>
  );
};

export default HeaderTwo;
